export const ServiceSectionArray = [
  {
    title: "Front-End Development",
    text: "This track deals with the aspect of your application that the users interact with. A front-end developer will be able to correctly interpret a given design to the user interface.",
    stack: "-HTML, CSS, JavaScript",
  },

  {
    title: "Back-End Development",
    text: "This track deals with the aspect of the application the user does not directly interact with. It allows the frontend function either by running some back-end code or connecting to the database.",
    stack: "-PHP, Python, JavaScript",
  },

  {
    title: "Design",
    text: "This track deals with creating a graphic plan for an application. You will learn to convert project documentation into viewable and understandable graphic design for the developers to work with",
    stack: "-UI, UX, Graphics Design",
  },

  {
    title: "Mobile",
    text: "The mobile track contains a list of tools and languages needed for a developer to create mobile applications. A mobile application can be IOS or Android.",
    stack: "-Java, Kotlin, Flutter",
  },

  {
    title: "Cloud",
    text: "The cloud track is an extension of the backend track. It involves cloud tools that’ll allow the participant to develop in the cloud using AWS or Azure cloud services",
    stack: "-AWS, Azure",
  },

  {
    title: "Frameworks",
    text: "During the program we will be exploring several useful frameworks to give you an edge in the industry and help you develop complex applications much faster",
    stack: "-Laravel, Django, React JS",
  },
];
