import styled from "styled-components";

export const ShowViewDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .showView_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .showView_profile_details {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        width: 80px;
        height: 80px;
      }

      .showView_profile_details_name {
        display: flex;
        flex-direction: column;
        gap: 12px;

        h4 {
          margin: 0;
          font-size: 16px;
          color: #292929;
          text-transform: capitalize;
          font-weight: 600;
        }

        p {
          margin: 0;
          font-size: 14px;
          color: #292929;
          font-weight: 400;
        }
      }
    }

    .cards_content_bottom_share {
      position: relative;

      .cards_content_bottom_share_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;

        .cards_content_bottom_share_icon_body {
          color: #101828;
          font-size: 24px;
        }
      }

      .social_media {
        position: absolute;
        right: 0;
        display: flex;
        z-index: 4;
        background-color: #ffffff;
        flex-direction: column;
        box-shadow: 0px 0px 8px 2px #00000025;
        padding: 8px 0px;
        border-radius: 8px;

        .social_media_container {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 12px;
          cursor: pointer;
          text-decoration: none;

          .social_media_container_icon_body {
            display: flex;
            align-items: center;
            justify-content: center;

            .social_media_container_icon {
              color: #101828;
              font-size: 18px;
            }
          }

          .social_media_container_paragraph {
            margin: 0;
            font-size: 15px;
            font-weight: 400;
            color: #101828;
          }
        }
      }
    }
  }

  .showView_Wrapper_title {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;

    .showView_Wrapper__titleImageWrapper {
      max-width: 100%;
      position: relative;
      min-height: 30rem;
      max-height: 50rem;
      overflow: hidden;
      /* max-height: 80vh; */

      .showView_articleWrapper__titleImageWrapperItem {
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .showView_articleWrapper__titleDetails {
      width: 100%;
      /* margin-bottom: 20px; */

      .showView_articleWrapper__titleDetailsItem {
        /* font-family: "Inter", sans-serif; */
        /* font-family: "Public Sans", sans-serif; */
        /* font-family: "Nunito", sans-serif; */
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        line-height: 40px;
        color: #101828;
        margin: 0;
        text-transform: capitalize;
        transition: all 1.5s;
      }
    }
  }

  .showView_articleWrapper__articleDetails {
    width: 100%;

    .showView_articleWrapper__articleDetailsItem {
      /* font-family: "Public Sans", sans-serif; */
      /* font-family: "Nunito", sans-serif; */
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      margin: 0;
      line-height: 30px;
      max-width: 100%;
      overflow: auto;
      transition: all 1.5s;

      .image {
        width: 100%;

        img {
          max-width: 100%;
          max-height: 30rem;
        }
      }

      img {
        max-width: 100%;
        max-height: 30rem;
      }
    }
  }
`;
