import { styled } from "styled-components";

export const SingleAssessmentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  .assessment {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .assessment_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .assessment_header_container {
        p {
          font-size: 16px;
          font-weight: 500;
          color: #101828;
        }
      }
    }

    .assessment_title {
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-bottom: 20px;

      h3 {
        font-size: 24px;
        color: #272727;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        color: #272727;
        font-weight: 400;
      }
    }

    .assessment_description {
      p {
        font-size: 18px;
        color: #272727;
        font-weight: 500;
      }
    }

    .assessment_description_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;

      .assessment_description_container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 12px;

        .assessment_description_container_wrapper {
          p {
            font-size: 16px;
            color: #272727;
            font-weight: 600;
          }
        }

        .assessment_description_watch_wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .assessment_description_watch {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 30px;

            h3 {
              text-transform: capitalize;
              font-size: 16px;
              color: #272727;
              font-weight: 800;
            }

            p {
              font-size: 16px;
              color: #272727;
              font-weight: 600;
            }
          }

          p {
            font-size: 16px;
            color: #272727;
            font-weight: 600;
          }
        }
      }
    }

    .assessment_status {
      h3 {
        font-size: 18px;
        color: #272727;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        color: #272727;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }

  .task {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .task_wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .task_header {
        h3 {
          font-size: 20px;
          color: #272727;
          font-weight: 600;
        }
      }

      .task_header_content {
        display: flex;
        flex-direction: column;
        gap: 5px;

        h3 {
          font-size: 19px;
          color: #272727;
          font-weight: 500;
        }

        p {
          font-size: 16px;
          color: #272727;
          font-weight: 400;
        }
      }

      .task_header_task {
        display: flex;
        flex-direction: column;
        gap: 10px;

        p {
          font-size: 16px;
          color: #272727;
          font-weight: 400;
        }
      }
    }

    .task_wrapper_buttons {
      button {
        border: none;
        outline: none;
        background-color: #007bff;
        color: #ffffff;
        padding: 8px 15px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        border-radius: 20px;

        &:hover {
          background-color: #07315f;
        }
      }
    }
  }

  .task_state {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid #00a478;

    .wrapperUnpending {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      gap: 20px;
      padding: 20px 30px;

      h3 {
        font-size: 32px;
        font-weight: 700;
        color: #12b76a;
        text-transform: capitalize;
      }

      .wrapperUnpending_icon_body {
        display: flex;
        align-items: center;
        justify-content: center;

        .wrapperUnpending_icon {
          font-size: 40px;
          color: #12b76a;
        }
      }
    }
  }

  .task_state_danger {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    border: 1px solid #fda29b;

    .wrapperUnpending {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      gap: 20px;
      padding: 20px 30px;

      h3 {
        font-size: 32px;
        font-weight: 700;
        color: #f14437;
        text-transform: capitalize;
      }

      .wrapperUnpending_icon_body {
        display: flex;
        align-items: center;
        justify-content: center;

        .wrapperUnpending_icon {
          font-size: 40px;
          color: #f14437;
        }
      }
    }
  }
`;
