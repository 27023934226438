import React from "react";
import { ModalStyle } from "./style/modal.style";

const SectionModalCentered = ({ children, styleModel, open }) => {
  return (
    <ModalStyle style={open ? showModal : removeModal}>
      <div className="modalContainer" style={{ ...styleModel }}>
        {children}
      </div>
    </ModalStyle>
  );
};

const showModal = {
  display: "block",
};

const removeModal = {
  display: "none",
};

export default SectionModalCentered;
