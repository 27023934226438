import React, { useState } from "react";
import { TaskTabDiv } from "./styles.js/taskTab.styles";
import { FiChevronDown } from "react-icons/fi";

const dropdownArray = [
  { title: "All" },
  { title: "Submitted" },
  { title: "UnSubmitted" },
  { title: "Graded" },
  { title: "UnGraded" },
];

const TaskTab = () => {
  const [selectTabs, setSelectTabs] = useState("");
  const [showTab, setShowTab] = useState(false);

  return (
    <TaskTabDiv>
      <div className="taskTabs" onClick={() => setShowTab(!showTab)}>
        <p>{selectTabs ? selectTabs : "select a task"}</p>

        <div className="taskTabs_space">
          <FiChevronDown className="taskTabs_space_coin" />
        </div>
      </div>

      {showTab && (
        <div className="task_tab_dropdown">
          {dropdownArray?.map((item, key) => (
            <div
              key={key}
              className={`task_tab_dropdown_items ${
                selectTabs === item?.title ? "Selectedtabs" : ""
              }`}
              onClick={() => {
                setShowTab(false);
                setSelectTabs(item?.title);
              }}
            >
              <p>{item?.title}</p>
            </div>
          ))}
        </div>
      )}
    </TaskTabDiv>
  );
};

export default TaskTab;
