import React, { useState } from "react";
import { TrackDiv } from "./styles/track.style";
import { FaSun } from "react-icons/fa";
import { useSelector } from "react-redux";

const trackArray = [
  { name: "HTML", descritption: "Hyper text mackup language" },
  { name: "Git", descritption: "version control" },
  { name: "CSS", descritption: "Cascading Style Sheet" },
  { name: "Javascript", descritption: "Emacscript 6" },
  { name: "ReactJs", descritption: "JavaScript FrameWork" },
];

const Track = () => {
  const [Selection] = useState(0);

  const { scoreDetails } = useSelector((state) => state.user);

  return (
    <TrackDiv>
      <div className="track">
        <div className="track_header">
          <h3>Tracks</h3>
        </div>

        <div className="track_table">
          <div className="track_table_headers">
            <div className="track_table_headers_State SN">
              <p>S/N</p>
            </div>

            <div className="track_table_headers_State Name">
              <p>Name</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>Description</p>
            </div>

            <div className="track_table_headers_State Level">
              <p>Current Level</p>
            </div>
          </div>

          {trackArray?.map((item, key) => (
            <>
              {/* {width >= 640 ? ( */}
              <div className="track_table_body" key={key}>
                <div className="track_table_body_State S_SN">
                  <p>{key + 1}</p>
                </div>

                <div className="track_table_body_State S_Name">
                  <p>{item?.name}</p>
                </div>

                <div className="track_table_body_State S_Description">
                  <p>{item?.descritption}</p>
                </div>

                <div className="track_table_body_State S_Level">
                  <FaSun
                    className={`${
                      key === scoreDetails?.stage - 1
                        ? "track_table_body_State_S_Level_Selection"
                        : "track_table_body_State_S_Level"
                    }`}
                  />
                </div>
              </div>
              {/* ) : (
                <div>
                  <div></div>
                </div>
              )} */}
            </>
          ))}
        </div>
      </div>
    </TrackDiv>
  );
};

export default Track;
