import io from "socket.io-client";
import React from "react";
import { toast } from "react-toastify";
// https://techpaver-learn-backend.onrender.com
// https://techpaver-learn-test-backend.onrender.com
// http://localhost:4050

const SOCKET_URL = "https://techpaver-learn-backend.onrender.com";

export const socket = io.connect(SOCKET_URL);

export const AppContext = React.createContext();
