import React, { useContext, useEffect, useState } from "react";
import { LayoutDiv } from "./styles/layout.styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../features/user/userServiceSync";
import LoaderScreen from "./loaderScreen";
import { AppContext } from "../context/appContext";
import { updateChatNotification } from "../features/user/userSlices";

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const { socket } = useContext(AppContext);

  const getToken = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const navigation = useNavigate();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (!getToken) {
      navigation("/signup");
    } else {
      if (userDetails === null) {
        dispatch(getUser());

        socket.emit("check-for-notification", `admin-${userDetails?._id}`);
      }
    }
  }, [getToken]);

  socket.off("notifications").on("notifications", (room) => {
    if (room !== null) {
      dispatch(updateChatNotification(room));
    }
  });

  return (
    <LayoutDiv>
      {userDetails ? (
        <div className="layout">{children}</div>
      ) : (
        <LoaderScreen />
      )}
    </LayoutDiv>
  );
};

export default Layout;
