import React from "react";
import { EmptyStateDiv } from "./style/emptystate.style";
import FoundNot from "../../../Assets/images/notfound.svg";

const EmptyState = () => {
  return (
    <EmptyStateDiv>
      <img src={FoundNot} />
      <p>Not Found</p>
    </EmptyStateDiv>
  );
};

export default EmptyState;
