import { styled } from "styled-components";

export const EmptyStateDiv = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  p {
  }
`;
