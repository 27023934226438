import React, { useEffect } from "react";
import LoaderScreen from "../utils/loaderScreen";
import { GetSingleBlogger } from "../features/bloggers/bloggersServicesSync";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlogShowView from "../components/common/BlogShowView";
import { ShowBlogDiv } from "./styles/blog.styles";

const ViewBlog = () => {
  const dispatch = useDispatch();

  const { singleStoryPageLoader } = useSelector((state) => state.blogger);

  const { blogId } = useParams();

  useEffect(() => {
    dispatch(GetSingleBlogger(blogId));
  }, []);

  return (
    <ShowBlogDiv>
      <div className="blog">
        {singleStoryPageLoader ? <LoaderScreen /> : <BlogShowView />}
      </div>
    </ShowBlogDiv>
  );
};

export default ViewBlog;
