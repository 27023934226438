const { styled } = require("styled-components");

export const ProgramSectionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 98px 0px;

  .program_head {
    width: 38%;
    position: relative;

    .program_head_program {
      border-radius: 10px;

      @media screen and (max-width: 860px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1050px) {
      width: 45%;
    }

    @media screen and (max-width: 950px) {
      width: 50%;
    }

    @media screen and (max-width: 860px) {
      width: 60%;
    }

    @media screen and (max-width: 860px) {
      width: 100%;
    }
  }

  .program_body {
    width: 50%;

    .program_body_title {
      p {
        color: black;
        font-size: 24px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
      }
    }

    .program_body_content {
      .program_body_content_data {
        display: flex;
        align-items: center;
        gap: 29px;

        .program_body_content_data_box {
          width: 20px;
          height: 20px;
          border: 0.5px solid #007bff;
        }

        p {
          color: black;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }
      }
    }

    @media screen and (max-width: 1050px) {
      width: 45%;
    }

    @media screen and (max-width: 950px) {
      width: 40%;
    }

    @media screen and (max-width: 860px) {
      width: 35%;
    }

    @media screen and (max-width: 860px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 860px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
`;
