import styled from "styled-components";

export const CreatePostComponentDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  .createpost_component {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .createpost_component_header {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      .secondLayout {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          color: #5b5b5b;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }

        textarea {
          flex-grow: 1;
          color: #0c0c0c;
          font-size: 16px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
          outline: none;
          border: 0.5px solid #ced4da;
          padding: 5px 12px;
          margin: 0px;
          border-radius: 8px;
          background-color: #f5f6f4;
          resize: none;
        }

        @media screen and (max-width: 740px) {
          width: 100%;
        }
      }

      .picture_clone {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          color: #5b5b5b;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }

        .picture_clone_picker {
          display: flex;
          align-items: center;
          gap: 20px;

          .picture_clone_picker_container {
            width: 100px;
            height: 50px;
            position: relative;

            .picture_clone_picker_container_icon_body {
              display: flex;
              align-items: flex-start;
              /* justify-content: center; */

              .picture_clone_picker_container_icon {
                font-size: 54px;
                color: #000;
              }
            }

            .picture_clone_picker_container_file {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }

          .picture_clone_picker_content {
            margin: 0;
            font-size: 14px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
          }
        }

        @media screen and (max-width: 740px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 740px) {
        gap: 30px;
      }
    }

    .createpost_component_post {
      display: flex;
      flex-direction: column;
      gap: 5px;

      label {
        color: #5b5b5b;
        font-size: 14px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
      }
    }
  }

  .create__Post__container {
    button {
      border: none;
      outline: none;
      background-color: #007bff;
      padding: 4px 49px;
      color: white;
      font-size: 16px;
      /* font-family: Roboto; */
      font-weight: 500;
      line-height: 30px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
`;
