import { styled } from "styled-components";

export const About_usDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 0px;

  .about_us {
    width: 50%;
    text-align: center;

    .about_us_header {
      h3 {
        color: black;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .about_us_content {
      p {
        color: black;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
      }
    }

    .about_us_button {
      button {
        background-color: transparent;
        border: 1px solid #1171d0;
        outline: none;
        color: #1171d0;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 1121px) {
      width: 65%;
    }

    @media screen and (max-width: 950px) {
      width: 75%;
    }

    @media screen and (max-width: 650px) {
      width: 85%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`;
