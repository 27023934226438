import React, { useContext, useEffect, useState } from "react";
import { ChatComponentDiv } from "./styles/chat.style";
import Profile from "../../../Assets/images/avatar-profile-photo.svg";
import { FiPaperclip } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../../context/appContext";
import ScrollToBottom from "react-scroll-to-bottom";

const arr = [{}, {}, {}, {}, {}, {}, {}, {}];

const ChatComponent = () => {
  const dispatch = useDispatch();

  const { socket } = useContext(AppContext);

  const { userDetails } = useSelector((state) => state.user);

  const [messages, setMessages] = useState();

  const [typedMessage, setTypedMessage] = useState();

  const [rooms, setRooms] = useState();

  socket.off("room-messages").on("room-messages", (roomMessages) => {
    console.log(roomMessages, "seeing me to you");

    setMessages(roomMessages);
  });

  useEffect(() => {
    socket.emit("check-for-room", `admin-${userDetails?._id}`);
  }, []);

  socket.off("checked-room").on("checked-room", (room) => {
    setRooms(room);
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setTypedMessage(value);
  };

  const getFormattedDate = () => {
    const date = new Date();

    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();

    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();

    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  };

  const todayDate = getFormattedDate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!typedMessage) return;

    if (rooms?.room !== `admin-${userDetails?._id}`) {
      socket.emit("create-user", {
        name: userDetails?.username,
        email: userDetails?.email,
        picture: userDetails?.profile_pic,
        roomOwner: userDetails?._id,
        room: `admin-${userDetails?._id}`,
      });
    }

    let today = new Date();

    const minutes =
      today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();

    const time = today.getHours() + ":" + minutes;

    const roomId = `admin-${userDetails?._id}`;

    socket.emit(
      "message-room",
      roomId,
      typedMessage,
      userDetails,
      time,
      todayDate
    );

    setTypedMessage("");
  };

  console.log(rooms, "seeing me looking up now");

  return (
    <ChatComponentDiv>
      <div className="userChat">
        <div className="userChat_input">
          <input placeholder="type username" className="userChat_input_state" />
        </div>
        <div className="userChat_user">
          {/* {arr.map((item, key) => ( */}
          <div className="userChat_user_content">
            <div className="userChat_user_content_image">
              <img
                src={Profile}
                className="userChat_user_content_image_content"
              />
              <div className="userChat_user_content_image_indicator" />
            </div>
            <div className="userChat_user_content_details">
              <h3 className="userChat_user_content_details_header">Admin</h3>
              <p className="userChat_user_content_details_paragraph">
                {rooms?.client_notification}
              </p>
            </div>
          </div>
          {/* ))} */}
        </div>
      </div>
      <div className="userChatComponent">
        <div className="userChatComponent_header">
          <div className="userChatComponent_header_image">
            <img
              src={Profile}
              className="userChatComponent_header_image_content"
            />
          </div>
          <div className="userChatComponent_header_details">
            <h2>Admin</h2>
            <p>{`was onLine today at 11:30`}</p>
          </div>
        </div>

        <div className="userChatComponent_body">
          <ScrollToBottom
            className="userChatComponent_body_wrapper"
            scrollViewClassName="__ScrollView"
          >
            {messages?.map((item, key) => (
              <div
                key={key}
                className="userChatComponent_body_content_container"
              >
                <div className="userChatComponent_body_content_date">
                  <p>{item?._id}</p>
                </div>

                {item?.messagesByDate?.map((data, ind) => (
                  <div className="userChatComponent_body_content" key={ind}>
                    {data?.from?._id !== userDetails?._id ? (
                      <div className="userChatComponent_body_content_left">
                        <div className="userChatComponent_body_content_left_container">
                          <div className="userChatComponent_body_content_left_container_header">
                            <h2>{data?.from?.username}</h2>
                            <p>{data?.time}</p>
                          </div>
                          <div className="userChatComponent_body_content_left_container_body">
                            <p>{data?.content}</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="userChatComponent_body_content_right">
                        <div className="userChatComponent_body_content_right_container">
                          <div className="userChatComponent_body_content_right_container_header">
                            <h2>{data?.from?.username}</h2>
                            <p>{data?.time}</p>
                          </div>
                          <div className="userChatComponent_body_content_right_container_body">
                            <p>{data?.content}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </ScrollToBottom>

          <div className="userChatComponent_body_set">
            <div className="userChatComponent_body_set_body">
              <textarea
                type="text"
                value={typedMessage || ""}
                autoComplete="off"
                onChange={handleChange}
                placeholder="send a message..."
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
                className="userChatComponent_body_set_input"
              ></textarea>

              <div className="userChatComponent_body_set_content">
                <div className="userChatComponent_body_set_content_icon">
                  <FiPaperclip className="userChatComponent_body_set_content_icon_body" />
                </div>

                <button
                  className="userChatComponent_body_set_content_button"
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ChatComponentDiv>
  );
};

export default ChatComponent;
