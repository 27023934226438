import React from "react";
import { HeroSectionDiv } from "./styles/heroSection.style";
import ImageOne from "../../Assets/image 1.svg";
import ImageTwo from "../../Assets/image 2.svg";
import ImageThree from "../../Assets/image 3.svg";
import ImageFour from "../../Assets/image 4.svg";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <HeroSectionDiv>
      <div className={"hero_half_state_first"}>
        <h2 className={"hero_half_state_first_header"}>
          Grow your tech skills for free today
        </h2>
        <p className={"hero_half_state_first_paragraphy"}>
          Build your tech skiils today by joining our free training program with
          no cost.You get a basic introduction to software development and
          design aimed at complete beginners which anyone can join. Each week
          you'll be given course content, have opportunity to attend live
          classes and work on tasks to help you understand each topic better.
        </p>
        <div className={"hero_half_state_first_button"}>
          <button
            className={"hero_half_state_first_button_data"}
            onClick={() => navigate("/signup")}
          >
            Get Started
          </button>
        </div>
      </div>
      <div className={"hero_half_state"}>
        <div className={"hero_half_state_image_container"}>
          <div className={"hero_half_state_image"}>
            <img src={ImageOne} />
          </div>
          <div className={"hero_half_state_image_center"}>
            <img src={ImageTwo} /> <img src={ImageThree} />
          </div>
          <div className={"hero_half_state_image"}>
            <img src={ImageFour} />
          </div>
        </div>
      </div>
    </HeroSectionDiv>
  );
};

export default HeroSection;
