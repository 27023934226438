import { styled } from "styled-components";

export const ChatComponentDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .userChat {
    width: 30%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px;

    .userChat_input {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .userChat_input_state {
        flex-grow: 1;
        outline: none;
        border: 1px solid #d0d5dd;
        padding: 10px 12px;
        font-size: 14px;
        color: #101828;
        border-radius: 8px;
      }

      @media screen and (max-width: 740px) {
        width: unset;
        padding: 20px 10px;
      }
    }

    .userChat_user {
      width: 100%;
      height: 500px;
      margin: 30px 0px 0px 0px;
      padding: 10px 0px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: scroll;

      &::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        border-radius: 0px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar {
        width: 0px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        background-color: #98a2b3;
      }

      .userChat_user_content {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 8px 0px;
        border-bottom: 0.5px solid #d0d5dd;
        cursor: pointer;

        .userChat_user_content_image {
          width: 60px;
          height: 60px;
          position: relative;

          .userChat_user_content_image_content {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }

          .userChat_user_content_image_indicator {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #2eb980;
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .userChat_user_content_details {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .userChat_user_content_details_header {
            margin: 0;
            font-size: 16px;
            font-weight: 700;
            color: #101828;
          }

          .userChat_user_content_details_paragraph {
            margin: 0;
            font-size: 13px;
            font-weight: 300;
            color: #f8f9fb;
            background-color: #fa0400;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      @media screen and (max-width: 740px) {
        width: unset;
        padding: 0px 20px;
      }
    }

    @media screen and (max-width: 740px) {
      width: 100%;
      padding: 30px 0px;
    }
  }

  .userChatComponent {
    width: 60%;
    height: 645px;
    background-color: #ffffff;
    border-radius: 8px;

    .userChatComponent_header {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 30px 20px 15px 20px;
      border-bottom: 1px solid #98a2b3;

      .userChatComponent_header_image {
        width: 60px;
        height: 60px;

        .userChatComponent_header_image_content {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .userChatComponent_header_details {
        h2 {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
          color: #101828;
        }

        p {
          margin: 0;
          font-size: 13px;
          font-weight: 300;
          color: #101828;
        }
      }
    }

    .userChatComponent_body {
      width: 100%;
      padding: 30px 0px 20px 0px;
      height: 470px;
      position: relative;

      .__ScrollView {
        &::-webkit-scrollbar-track {
          /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
          border-radius: 5px;
          /* background-color: #f5f5f5; */
        }

        &::-webkit-scrollbar {
          width: 5px;
          /* background-color: #f5f5f5; */
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
          background-color: #98a2b3;
        }
      }

      .userChatComponent_body_wrapper {
        /* width: 100%; */
        height: 350px;
        overflow-y: scroll;

        .userChatComponent_body_content_container {
          display: flex;
          flex-direction: column;
          gap: 30px;

          .userChatComponent_body_content_date {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .userChatComponent_body_content {
            padding: 20px 0px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 95%;

            margin: 0 auto;

            .userChatComponent_body_content_left {
              display: flex;
              justify-content: flex-start;
              width: 100%;

              .userChatComponent_body_content_left_container {
                max-width: 70%;
                min-width: 30%;
                display: flex;
                flex-direction: column;
                gap: 5px;

                .userChatComponent_body_content_left_container_header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;

                  h2 {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 700;
                    color: #101828;
                  }

                  p {
                    margin: 0;
                    font-size: 13px;
                    font-weight: 300;
                    color: #101828;
                  }
                }

                .userChatComponent_body_content_left_container_body {
                  background-color: #f7f7f7;
                  padding: 10px;
                  border-radius: 0px 8px 8px 8px;

                  p {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #101828;
                  }
                }
              }
            }

            .userChatComponent_body_content_right {
              display: flex;
              justify-content: flex-end;
              width: 100%;

              .userChatComponent_body_content_right_container {
                max-width: 70%;
                min-width: 30%;
                display: flex;
                flex-direction: column;
                gap: 5px;

                .userChatComponent_body_content_right_container_header {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;

                  h2 {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 700;
                    color: #101828;
                  }

                  p {
                    margin: 0;
                    font-size: 13px;
                    font-weight: 300;
                    color: #101828;
                  }
                }

                .userChatComponent_body_content_right_container_body {
                  background-color: #eef1ff;
                  padding: 10px;
                  border-radius: 8px 0px 8px 8px;

                  p {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #101828;
                  }
                }
              }
            }
          }
        }
      }

      .userChatComponent_body_set {
        flex-grow: 1;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 14px 0px;

        .userChatComponent_body_set_body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          background-color: #f7f7f7;
          padding: 10px 14px;
          border-radius: 8px;
          width: 90%;
          margin: 0 auto;

          .userChatComponent_body_set_input {
            flex-grow: 1;
            outline: none;
            border: none;
            background-color: transparent;
            padding: 8px 12px;
            font-size: 14px;
            color: #101828;
            border-radius: 8px;
            resize: none;
          }

          .userChatComponent_body_set_content {
            display: flex;
            align-items: center;
            gap: 20px;

            .userChatComponent_body_set_content_icon {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              .userChatComponent_body_set_content_icon_body {
                font-size: 18px;
                color: #101828;
              }
            }

            .userChatComponent_body_set_content_button {
              background-color: #1171d0;
              color: #ffffff;
              outline: none;
              border: none;
              padding: 8px 18px;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media screen and (max-width: 740px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
    gap: 40px;
  }
`;
