import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import UserServices from "./userService";

export const getUser = createAsyncThunk("user/user", async () => {
  try {
    let response = await UserServices.getUserDetails();

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const editUser = createAsyncThunk("user/edit_user", async (payload) => {
  try {
    let response = await UserServices.editUserDetails(payload);

    return response;
  } catch (error) {
    toast(error.message);
    throw error;
  }
});

export const editUserPassword = createAsyncThunk(
  "user/edit_user_password",
  async (payload) => {
    try {
      let response = await UserServices.editUserPassword(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const getUserScoresRating = createAsyncThunk(
  "user/scoresrating",
  async () => {
    try {
      let response = await UserServices.getScoreStateDetails();

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
