import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const DashboardProtectedRoute = ({ Component, ...props }) => {
  const getToken = localStorage.getItem("token");

  return getToken ? <Outlet /> : <Navigate to={"/login"} />;
};

export default DashboardProtectedRoute;
