import React, { useState } from "react";
import { NavDiv } from "./styles/nav.styles";
import { NavLinks } from "../../utils/navLinks";
import { FiMenu } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";

const Nav = () => {
  const [show, setShow] = useState(false);

  const getToken = localStorage.getItem("token");

  const navigator = useNavigate();

  return (
    <NavDiv show={show}>
      <div className="nav_container">
        <div className="nav_container_fronContainer">
          <div className="nav_container_logo" onClick={() => navigator("/")}>
            <h2>Techpaver</h2>
          </div>

          <div
            className="nav_container_logo_menu"
            onClick={() => setShow(!show)}
          >
            <FiMenu />
          </div>
        </div>

        <div className="nav_main_body_wrapper">
          <div className="nav_main_body">
            {NavLinks?.map((item, key) => (
              <>
                {item?.title === "Login" || item?.title === "SignUp" ? (
                  <>
                    {getToken ? (
                      <>
                        {item?.title === "Login" && (
                          <NavLink
                            exact
                            to={`/dashboard`}
                            className={"nav"}
                            activeClassName={"activeNav"}
                            onClick={() => setShow(false)}
                          >
                            <p>Dashboard</p>
                          </NavLink>
                        )}
                      </>
                    ) : (
                      <NavLink
                        exact
                        to={`${item?.link}`}
                        className={"nav"}
                        activeClassName={"activeNav"}
                        onClick={() => setShow(false)}
                      >
                        <p>{item?.title}</p>
                      </NavLink>
                    )}
                  </>
                ) : (
                  <NavLink
                    exact
                    to={`${item?.link}`}
                    className={"nav"}
                    activeClassName={"activeNav"}
                    onClick={() => setShow(false)}
                  >
                    <p>{item?.title}</p>
                  </NavLink>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </NavDiv>
  );
};

export default Nav;
