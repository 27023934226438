import React, { useRef, useState } from "react";
import ProfilePic from "../../../Assets/images/avatar-profile-photo.svg";
import CardImage from "../../../Assets/program.svg";
import { FiShare2 } from "react-icons/fi";
import { BlogShowViewDiv } from "./styles/blogShowView.style";
import { useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { BsLinkedin, BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { BiSolidCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const BlogShowView = () => {
  const dropRef = useRef();

  const [selectedId, setSelectedId] = useState();

  const { singleStoryPageDetails } = useSelector((state) => state.blogger);

  useOnClickOutside(dropRef, () => setSelectedId());

  return (
    <BlogShowViewDiv>
      <div className="showView_header">
        <div className="showView_profile_details">
          <img src={ProfilePic} />

          <div className="showView_profile_details_name">
            <h4>{singleStoryPageDetails?.title}</h4>
            <p>
              {moment(singleStoryPageDetails?.date).format(
                "MMM DD, YYYY hh:mm"
              )}
            </p>
          </div>
        </div>
        <div className="cards_content_bottom_share">
          <div
            className="cards_content_bottom_share_icon"
            onClick={() => setSelectedId(singleStoryPageDetails?._id)}
          >
            <FiShare2 className="cards_content_bottom_share_icon_body" />
          </div>

          {selectedId && (
            <div className="social_media" ref={dropRef}>
              <CopyToClipboard
                text={`${baseUrl}/${singleStoryPageDetails?._id}`}
                onCopy={() => toast("successfully copied")}
              >
                <div className="social_media_container">
                  <div className="social_media_container_icon_body">
                    <BiSolidCopy className="social_media_container_icon" />
                  </div>
                  <p className="social_media_container_paragraph">CopyLink</p>
                </div>
              </CopyToClipboard>

              <NavLink
                to={`https://www.linkedin.com/shareArticle?url=${baseUrl}/${singleStoryPageDetails._id}&title=${singleStoryPageDetails?.title}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsLinkedin className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Linkedin</p>
              </NavLink>

              <NavLink
                to={`https://www.facebook.com/sharer.php?u=${baseUrl}/${singleStoryPageDetails._id}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsFacebook className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">FaceBook</p>
              </NavLink>

              <NavLink
                to={`https://twitter.com/share?url=${baseUrl}/${singleStoryPageDetails._id}&text=${singleStoryPageDetails?.title}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsTwitter className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Twitter</p>
              </NavLink>

              <NavLink
                to={`https://api.whatsapp.com/send?text=${singleStoryPageDetails?.title} ${baseUrl}/${singleStoryPageDetails._id}`}
                target="blank"
                className="social_media_container"
              >
                <div className="social_media_container_icon_body">
                  <BsWhatsapp className="social_media_container_icon" />
                </div>
                <p className="social_media_container_paragraph">Whatsapp</p>
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <div className="showView_Wrapper_title">
        <div className="showView_articleWrapper__titleDetails">
          <h2 className="showView_articleWrapper__titleDetailsItem">
            {singleStoryPageDetails?.title}
          </h2>
        </div>
        <div className="showView_Wrapper__titleImageWrapper">
          <img
            src={
              singleStoryPageDetails?.cover_pic
                ? `${singleStoryPageDetails?.cover_pic}`
                : CardImage
            }
            className="showView_articleWrapper__titleImageWrapperItem"
          />
        </div>
      </div>
      <div className="showView_articleWrapper__articleDetails">
        {singleStoryPageDetails?.article && (
          <p className="showView_articleWrapper__articleDetailsItem">
            {HTMLReactParser(HTMLReactParser(singleStoryPageDetails?.article))}
          </p>
        )}
      </div>
      <div></div>
    </BlogShowViewDiv>
  );
};

export default BlogShowView;
