import { styled } from "styled-components";

export const LandingPageDiv = styled.div`
  width: 85%;
  margin: 0 auto;

  @media screen and (max-width: 740px) {
    width: 95%;
  }
`;

export const LandingPageDivWithBg = styled.div`
  width: 100%;
  background-color: #f9f9f9;

  .withBg {
    width: 85%;
    margin: 0 auto;

    @media screen and (max-width: 740px) {
      width: 95%;
    }
  }
`;
