import React from "react";
import { TaskTableDiv } from "./styles.js/taskTable.style";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import EmptyState from "../../common/emptyState";
import Skeleton from "react-loading-skeleton";

const trackArray = [
  {
    course: "Git",
    task: "version control",
    description: "Hyper text mackup language",
    prerequisite: "...",
    status: 0,
    timestamp: "12-02-2023",
  },
  {
    course: "HTML",
    task: "Hyper text mackup language",
    description: "Hyper text mackup language",
    prerequisite: "...",
    status: 0,
    timestamp: "12-02-2023",
  },
];

const TaskTable = () => {
  const { allAssessment, isLoading } = useSelector((state) => state.assessment);

  const navigate = useNavigate();

  return (
    <TaskTableDiv>
      <div className="track">
        <div className="track_header">
          <h3>Tasks</h3>
        </div>

        <div className="track_table">
          <div className="track_table_headers">
            <div className="track_table_headers_State SN">
              <p>S/N</p>
            </div>

            <div className="track_table_headers_State course">
              <p>COURSE</p>
            </div>

            <div className="track_table_headers_State Name">
              <p>TASK TITLE</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>DESCRIPTION</p>
            </div>

            <div className="track_table_headers_State Prerequisite">
              <p>SCORE</p>
            </div>

            <div className="track_table_headers_State Time_status">
              <p>TIME STATUS</p>
            </div>

            <div className="track_table_headers_State status">
              <p>STATUS</p>
            </div>

            <div className="track_table_headers_State Level">
              <p>ACTION</p>
            </div>
          </div>

          {!isLoading ? (
            <>
              {allAssessment?.length > 0 ? (
                allAssessment?.map((item, key) => (
                  <div className="track_table_body" key={key}>
                    <div className="track_table_body_State S_SN">
                      <p>{key + 1}</p>
                    </div>

                    <div className="track_table_body_State S_Course">
                      <p>{item?.course}</p>
                    </div>

                    <div className="track_table_body_State S_Name">
                      <p>{item?.title}</p>
                    </div>

                    <div className="track_table_body_State S_Description">
                      <p>{item?.description}</p>
                    </div>

                    <div className="track_table_body_State S_Prerequisite">
                      <p>{item?.score}</p>
                    </div>

                    <div className="track_table_body_State S_Time_status">
                      <Countdown
                        date={item?.time_status}
                        renderer={({ days, hours, minutes, seconds }) => (
                          <span>{`${days}:${hours}:${minutes}:${seconds}`}</span>
                        )}
                      />
                    </div>

                    <div className="track_table_body_State S_Status">
                      <p
                        style={{
                          color: `${
                            item?.status === "pending"
                              ? "##f4be37"
                              : item?.status === "submitted"
                              ? "#12b76a"
                              : "#f14437"
                          }`,
                        }}
                      >
                        {item?.status}
                      </p>
                    </div>

                    <div className="track_table_body_State S_Level">
                      <button
                        onClick={() => navigate(`/dashboard/task/${item?._id}`)}
                      >
                        View
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyState />
              )}
            </>
          ) : (
            <Skeleton height={80} width={"100%"} count={5} />
          )}
        </div>
      </div>
    </TaskTableDiv>
  );
};

export default TaskTable;
