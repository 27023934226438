import React from "react";
import { FooterDiv } from "./styles/footer.style";
import { MdLocationOn, MdMail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import BlogImageOne from "../../Assets/blogimageone.svg";
import BlogImageTwo from "../../Assets/blogimagetwo.svg";
import BlogImageThree from "../../Assets/blogimagethree.svg";

const Footer = () => {
  return (
    <FooterDiv>
      <div className="footer_header">
        <div className="footer_header_one">
          <div className="footer_header_one_title">
            <h2>Techpaver</h2>
          </div>
          <div className="footer_header_one_body">
            <p>
              Build your tech skiils today by joining our free training program
              with no cost. You get a basic introduction to software development
              and design aimed at complete beginners which anyone can join.
            </p>
          </div>
        </div>

        <div className="footer_header_two">
          <p>Latest Blog Post</p>

          <div className="footer_header_two_body">
            {blogArray?.map((item, key) => (
              <div key={key} className="footer_header_two_body_blog">
                <img src={item?.img} />
                <div key={key} className="footer_header_two_body_blog_body">
                  <p className="footer_header_two_body_blog_body_title">
                    {item?.title}
                  </p>

                  <p className="footer_header_two_body_blog_body_date">
                    {item?.date}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="footer_header_three">
          <p>Contact Us</p>

          <div className="footer_header_three_description">
            {DescriptionArray?.map((item, key) => (
              <div key={key} className="footer_header_three_description_body">
                <div className="footer_header_three_description_body_icon">
                  <item.icon className="footer_header_three_description_body_icons" />
                </div>
                <p>{item?.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="footer_bottom">
        <div className="footer_bottom_container">
          <div className="footer_bottom_training">
            <p>© 2022 Techpaver Training</p>
          </div>
          <div className="footer_bottom_wrapper">
            <div className="footer_bottom_wrapper_icon_body">
              <FaTwitter className="footer_bottom_wrapper_icon" />
            </div>

            <div className="footer_bottom_wrapper_icon_body">
              <FaLinkedin className="footer_bottom_wrapper_icon" />
            </div>

            <div className="footer_bottom_wrapper_icon_body">
              <FaInstagram className="footer_bottom_wrapper_icon" />
            </div>

            <div className="footer_bottom_wrapper_icon_body">
              <FaFacebookF className="footer_bottom_wrapper_icon" />
            </div>
          </div>
        </div>
      </div>
    </FooterDiv>
  );
};

const blogArray = [
  {
    img: BlogImageOne,
    title: "How to host a React Application",
    date: "12/04/2021",
  },
  {
    img: BlogImageTwo,
    title: "Dealing with CORS in Express Js",
    date: "12/04/2021",
  },
  {
    img: BlogImageThree,
    title: "How Hoisting works in Js",
    date: "12/04/2021",
  },
];

const DescriptionArray = [
  { icon: MdLocationOn, text: "33 democracy crescent Gaduwa Estate, Abuja" },
  { icon: BsFillTelephoneFill, text: "+234 8105768015" },
  { icon: MdMail, text: "techpaver.company@gmail.com" },
];

export default Footer;
