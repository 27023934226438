import { toast } from "react-toastify";
import APIs from "../apiUrl";

const submitAssessment = async (payload) => {
  try {
    const response = await APIs.post("/submit_assessment", payload);

    if (response?.data?.message === "success") {
      toast("Assessment Create Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllAssessment = async () => {
  try {
    const response = await APIs.get("/get_all_user_assessment");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getPendingAssessment = async () => {
  try {
    const response = await APIs.get("/get_all_user_pending_assessment");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSingleAssessment = async (payload) => {
  try {
    const response = await APIs.get(`/get_user_single_assessment/${payload}`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getAllUsersSubmittedAssessment = async () => {
  try {
    const response = await APIs.get(`/get_all_user_submitted_data`);

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getSinglesUsersSubmittedAssessment = async (payload) => {
  try {
    const response = await APIs.get(
      `/get_all_user_single_submitted_data/${payload}`
    );

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const AssessmentService = {
  submitAssessment,
  getAllAssessment,
  getPendingAssessment,
  getSingleAssessment,
  getAllUsersSubmittedAssessment,
  getSinglesUsersSubmittedAssessment,
};

export default AssessmentService;
