import React from "react";
import { EnrollForm } from "./styles/enrollform.style";
import ContactPic from "../../Assets/images/contact.svg";

const EnrolForm = () => {
  return (
    <EnrollForm>
      {/* <div className="enroll_form">
        <div className="enroll_form_two_form">
          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">First Name</label>
            <input className="enroll_form_two_form_one_input" />
          </div>

          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">Last Name</label>
            <input className="enroll_form_two_form_one_input" />
          </div>
        </div>

        <div className="enroll_form_two_form">
          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">Username</label>
            <input className="enroll_form_two_form_one_input" />
          </div>

          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">Age</label>
            <input className="enroll_form_two_form_one_input" />
          </div>
        </div>

        <div className="enroll_form_name">
          <label className="enroll_form_name_lable">Email Address</label>
          <input className="enroll_form_name_input" />
        </div>

        <div className="enroll_form_name">
          <label className="enroll_form_name_lable">Address</label>
          <input className="enroll_form_name_input" />
        </div>

        <div className="enroll_form_two_form">
          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">
              Educational Level
            </label>
            <input className="enroll_form_two_form_one_input" />
          </div>

          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">
              How did you hear About us
            </label>
            <input className="enroll_form_two_form_one_input" />
          </div>
        </div>

        <div className="enroll_form_two_form">
          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">
              Phone Number
            </label>
            <input className="enroll_form_two_form_one_input" />
          </div>

          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">Track</label>
            <input className="enroll_form_two_form_one_input" />
          </div>
        </div>
        <div className="enroll_form_two_form">
          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">Gender</label>
            <input className="enroll_form_two_form_one_input" />
          </div>
          <div className="enroll_form_two_form_one">
            <label className="enroll_form_two_form_one_label">
              Experience Level
            </label>
            <input className="enroll_form_two_form_one_input" />
          </div>
        </div>
        <div className="enroll_form_button">
          <button>Register</button>
        </div>
      </div> */}

      <div className="contact_content_pic">
        <img src={ContactPic} />
      </div>

      <div className="enroll_content">
        <div className="enroll_content_title">
          <p>Enroll Today</p>
        </div>
        <div className="enroll_content_details">
          <div className="enroll_content_details_content">
            <p>
              Kick start your tech career by registering with us for the 2023
              Techpaver Training. Be sure to fill in your details with a valid
              email and a working phone nunber. This is to ensure that you are
              invited to the workspace.
            </p>
          </div>

          <div className="enroll_content_details_content">
            <p>
              The program will officially kick-off on the 1st of November, 2023.
              Once registered, you will recieve an emial from us confirming your
              registration.
            </p>
          </div>

          <div className="enroll_content_details_content">
            <p>
              Please note that you will not be able to change your tracks untill
              the program has commerced. It is advisable to consider your
              options and seek advice before you choose your track.
            </p>
          </div>
        </div>
      </div>
    </EnrollForm>
  );
};

export default EnrolForm;
