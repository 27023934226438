import { styled } from "styled-components";

export const HeroSectionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  padding-top: 100px;

  .hero_half_state_first {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2px;

    @media screen and (max-width: 1080px) {
      gap: 0px;
      width: 70%;
    }

    @media screen and (max-width: 1080px) {
      width: 100%;
    }

    .hero_half_state_first_header {
      color: black;
      font-size: 50px;
      font-weight: 600;
      line-height: 70px;
      text-transform: capitalize;

      @media screen and (max-width: 1080px) {
        font-size: 45px;
        line-height: 60px;
      }
    }

    .hero_half_state_first_paragraphy {
      color: black;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
    }

    .hero_half_state_first_button {
      .hero_half_state_first_button_data {
        background-color: #1171d0;
        border: none;
        outline: none;
        color: #fff;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }

  .hero_half_state {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;

    .hero_half_state_image_container {
      width: 69%;

      .hero_half_state_image {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 151px;
          height: 151px;

          @media screen and (max-width: 1080px) {
            width: 141px;
            height: 141px;
          }

          @media screen and (max-width: 510px) {
            width: 121px;
            height: 121px;
          }
        }
      }

      .hero_half_state_image_center {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 151px;
          height: 151px;

          @media screen and (max-width: 1080px) {
            width: 121px;
            height: 121px;
          }

          @media screen and (max-width: 510px) {
            width: 93%;
          }
        }
      }

      @media screen and (max-width: 1560px) {
        width: 80%;
      }

      @media screen and (max-width: 1420px) {
        width: 86%;
      }

      @media screen and (max-width: 1285px) {
        width: 94%;
      }

      @media screen and (max-width: 1150px) {
        width: 100%;
      }

      @media screen and (max-width: 1080px) {
        width: 56%;
      }

      @media screen and (max-width: 815px) {
        width: 64%;
      }

      @media screen and (max-width: 770px) {
        width: 68%;
      }

      @media screen and (max-width: 720px) {
        width: 70%;
      }

      @media screen and (max-width: 714px) {
        width: 75%;
      }

      @media screen and (max-width: 650px) {
        width: 80%;
      }

      @media screen and (max-width: 650px) {
        width: 80%;
      }

      @media screen and (max-width: 510px) {
        width: 93%;
      }

      @media screen and (max-width: 420px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1080px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    gap: 50px;
  }
`;
