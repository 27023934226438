import React from "react";
import { About_usDiv } from "./styles/about_us.style";

const AboutUs = () => {
  return (
    <About_usDiv>
      <div className="about_us">
        <div className="about_us_header">
          <h3>Who We Are</h3>
        </div>
        <div className="about_us_content">
          <p>
            Techpaver is actively empowering the lives of youths and teenagers
            globally by actively teaching and providing mentorship for the
            software development careers. The program is a 6-month long program
            aimed at getting you started in the industry. We understand it is
            impossible to become an expert at anything in just 6 months.
            However, we promise to give you everything you need to get started.
          </p>
        </div>
        <div className="about_us_button">
          <button>More About Us</button>
        </div>
      </div>
    </About_usDiv>
  );
};

export default AboutUs;
