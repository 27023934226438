import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import HTMLReactParser from "html-react-parser";

import { toast } from "react-toastify";
import BloggersServices from "../../../features/bloggers/bloggersServices";
import {
  checkImageDetails,
  checkLoader,
  checkMessage,
} from "../../../features/bloggers/bloggersSlices";

const MyEditor = ({ handleEditor, data }) => {
  const dispatch = useDispatch();

  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  const uploadAdapter = (loader) => {
    dispatch(checkLoader(true));
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("uploadImg", file);
            // fetch()
            let promise = getbase64(file);

            promise.then(async (data) => {
              return BloggersServices.BloggerImageUpload({ file: data })
                .then((res) => {
                  dispatch(checkMessage(res.message));
                  dispatch(checkImageDetails(res.data));

                  return res?.data?.secure_url;
                })
                .then((response) => {
                  dispatch(checkLoader(false));
                  resolve({ default: response });
                })
                .catch((err) => {
                  toast(err.message);
                  dispatch(checkLoader(false));
                  dispatch(checkMessage(err.message));
                  reject(err);
                });
            });
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const arrSum = (array) => {
    let result = 0;

    for (let i = 0; i < array.length; i++) {
      if (typeof array[i] === "string") {
        result += array[i].replace(/\s\s+/g, " ").split(" ").length * 1;
      } else {
        if (Array.isArray(array[i].props.children)) {
          result += arrSum(array[i].props.children);
        } else if (typeof array[i].props.children === "string") {
          result +=
            array[i].props.children.replace(/\s\s+/g, " ").split(" ").length *
            1;
        }
      }
    }

    return result;
  };

  return (
    <div>
      <CKEditor
        style={{ height: "100%" }}
        config={{
          extraPlugins: [uploadPlugin],

          // wordCount: {
          //   onUpdate: (stats) => {
          //     // Prints the current content statistics.
          //     console.log(
          //       `Characters: ${stats.characters}\nWords: ${stats.words}`
          //     );
          //   },
          // },

          image: {
            resizeOptions: [
              {
                name: "resizeImage:original",
                value: null,
                label: "Original",
              },
              {
                name: "resizeImage:40",
                value: "40",
                label: "40%",
              },
              {
                name: "resizeImage:60",
                value: "60",
                label: "60%",
              },
            ],
            toolbar: [
              "imageStyle:inline",
              "imageStyle:block",
              "imageStyle:side",
              "|",
              "ImageResize",
              "|",
              "toggleImageCaption",
              "imageTextAlternative",
              "|",
              "linkImage",
            ],
          },
        }}
        editor={ClassicEditor}
        // config={{
        //   plugins: [Paragraph, Bold, Italic, Essentials],
        //   toolbar: ["bold", "italic"],
        // }}
        data={data}
        onReady={(editor) => {
          if (editor?.model?.schema?.isRegistered("image")) {
            editor?.model?.schema?.extend("image", {
              allowAttributes: "blockIndent",
            });
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          let wordCount = 0;

          if (Array.isArray(HTMLReactParser(data))) {
            wordCount = arrSum(HTMLReactParser(data));
          } else {
            let wordArray = HTMLReactParser(data).props.children;

            if (typeof wordArray === "string") {
              wordCount = wordArray.replace(/\s\s+/g, " ").split(" ").length;
            } else {
              wordCount = arrSum(wordArray);
            }
          }

          handleEditor(data, wordCount);
        }}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
      />
    </div>
  );
};

export default MyEditor;
