import { createSlice } from "@reduxjs/toolkit";

import {
  editUser,
  editUserPassword,
  getUser,
  getUserScoresRating,
} from "./userServiceSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  isRegister: false,
  message: "",
  userDetails: null,
  editLoader: false,
  issearchingLoading: false,
  scoreDetails: {},
  scoreArray: [],
  chatNotification: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.userDetails = null;
      state.editLoader = false;
      state.issearchingLoading = false;
      state.scoreDetails = {};
      state.scoreArray = [];
      state.chatNotification = 0;
    },

    EditedLoader: (state, action) => {
      state.editLoader = action.payload;
    },

    updateChatNotification: (state, action) => {
      state.chatNotification = action.payload;
    },
  },
  extraReducers: {
    [getUser.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
      state.userDetails = action.payload?.data;
    },

    [getUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [editUser.pending]: (state, action) => {
      state.editLoader = true;
    },

    [editUser.fulfilled]: (state, action) => {
      state.editLoader = false;
      state.message = action.payload?.message;
      state.userDetails = action.payload?.data;
    },

    [editUser.rejected]: (state, action) => {
      state.editLoader = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [editUserPassword.pending]: (state, action) => {
      state.editLoader = true;
    },

    [editUserPassword.fulfilled]: (state, action) => {
      state.editLoader = false;
      state.message = action.payload?.message;
      state.userDetails = action.payload?.data;
    },

    [editUserPassword.rejected]: (state, action) => {
      state.editLoader = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [getUserScoresRating.pending]: (state, action) => {
      state.issearchingLoading = true;
    },

    [getUserScoresRating.fulfilled]: (state, action) => {
      state.issearchingLoading = false;
      state.message = action.payload?.message;
      state.scoreDetails = action.payload?.data;
      state.scoreArray = action.payload.content;
    },

    [getUserScoresRating.rejected]: (state, action) => {
      state.issearchingLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, EditedLoader, updateChatNotification } =
  userSlice.actions;
export default userSlice.reducer;
