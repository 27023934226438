import React, { useRef, useState } from "react";
import CardImage from "../../../Assets/program.svg";
import ProfilePic from "../../../Assets/images/avatar-profile-photo.svg";
import { FiShare2 } from "react-icons/fi";
import { NotFound, StoriesCardDiv } from "./styles/storiesCard.style";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import { BsLinkedin, BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { BiSolidCopy } from "react-icons/bi";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { NavLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";

const StoriesCard = ({ cardArry }) => {
  const dropRef = useRef();

  const [selectedId, setSelectedId] = useState();

  useOnClickOutside(dropRef, () => setSelectedId());
  return (
    <>
      {cardArry?.length > 0 ? (
        cardArry?.map((item, key) => (
          <StoriesCardDiv key={key}>
            <div className="cards_component">
              <div className="cards_image">
                <img src={item?.cover_pic ? `${item?.cover_pic}` : CardImage} />
              </div>

              <div className="cards_content">
                <NavLink
                  to={`${baseUrl}/dashboard/${item?._id}`}
                  target="blank"
                  className="cards_content_header"
                >
                  <div className="cards_content_header_Profile">
                    <img
                      src={item?.profile_pic ? item?.profile_pic : ProfilePic}
                    />
                    <h4>{item?.username}</h4>
                  </div>
                  <div className="cards_content_header_content">
                    <h3>{item?.title}</h3>

                    <p>{HTMLReactParser(HTMLReactParser(item?.article))}</p>
                  </div>
                </NavLink>
                <div className="cards_content_bottom">
                  <div className="cards_content_bottom_time">
                    <p>{`${moment(item?.date).format(
                      "MMM DD, YYYY hh:mm"
                    )}`}</p>
                  </div>

                  <div className="cards_content_bottom_share">
                    <div
                      className="cards_content_bottom_share_icon"
                      onClick={() => setSelectedId(item?._id)}
                    >
                      <FiShare2 className="cards_content_bottom_share_icon_body" />
                    </div>

                    {selectedId === item?._id && (
                      <div className="social_media" ref={dropRef}>
                        <CopyToClipboard
                          text={`${baseUrl}/${item?._id}`}
                          onCopy={() => toast("successfully copied")}
                        >
                          <div className="social_media_container">
                            <div className="social_media_container_icon_body">
                              <BiSolidCopy className="social_media_container_icon" />
                            </div>
                            <p className="social_media_container_paragraph">
                              CopyLink
                            </p>
                          </div>
                        </CopyToClipboard>

                        <NavLink
                          to={`https://www.linkedin.com/shareArticle?url=${baseUrl}/${item._id}&title=${item?.title}`}
                          target="blank"
                          className="social_media_container"
                        >
                          <div className="social_media_container_icon_body">
                            <BsLinkedin className="social_media_container_icon" />
                          </div>
                          <p className="social_media_container_paragraph">
                            Linkedin
                          </p>
                        </NavLink>

                        <NavLink
                          to={`https://www.facebook.com/sharer.php?u=${baseUrl}/${item._id}`}
                          target="blank"
                          className="social_media_container"
                        >
                          <div className="social_media_container_icon_body">
                            <BsFacebook className="social_media_container_icon" />
                          </div>
                          <p className="social_media_container_paragraph">
                            FaceBook
                          </p>
                        </NavLink>

                        <NavLink
                          to={`https://twitter.com/share?url=${baseUrl}/${item._id}&text=${item?.title}`}
                          target="blank"
                          className="social_media_container"
                        >
                          <div className="social_media_container_icon_body">
                            <BsTwitter className="social_media_container_icon" />
                          </div>
                          <p className="social_media_container_paragraph">
                            Twitter
                          </p>
                        </NavLink>

                        <NavLink
                          to={`https://api.whatsapp.com/send?text=${item?.title} ${baseUrl}/${item._id}`}
                          target="blank"
                          className="social_media_container"
                        >
                          <div className="social_media_container_icon_body">
                            <BsWhatsapp className="social_media_container_icon" />
                          </div>
                          <p className="social_media_container_paragraph">
                            Whatsapp
                          </p>
                        </NavLink>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </StoriesCardDiv>
        ))
      ) : (
        <NotFound>
          <div className="not_found_wrapper">
            <p>No Article found</p>
            <button>Create one</button>
          </div>
        </NotFound>
      )}
    </>
  );
};

export default StoriesCard;
