import { styled } from "styled-components";

export const SignupDiv = styled.div`
  width: 100%;
  padding: 60px 0px;
  padding-top: 100px;

  .sign_up {
    width: 70%;
    margin: 0 auto;

    .sign_up_contract {
      display: flex;
      flex-direction: column;
      gap: 20px;
      /* align-items: center;
      justify-content: center; */

      .sign_up_contract_registration {
        display: flex;
        flex-direction: column;
        gap: 15px;

        h3 {
          margin: 0;
          color: #0c0c0c;
          font-size: 24px;
          /* font-family: Roboto; */
          font-weight: 500;
          line-height: 30px;

          &.seesa {
            color: #007bff;
          }
        }
      }

      .sign_up_contract_registration_2 {
        display: flex;
        align-items: flex-start;
        /* justify-content: center; */

        h2 {
          margin: 0;
          color: #007bff;
          font-size: 42px;
          /* font-family: Roboto; */
          font-weight: 700;
          line-height: 39px;

          @media screen and (max-width: 740px) {
            font-size: 24px;
          }
        }
      }
    }

    display: flex;
    flex-direction: column;
    gap: 30px;

    .signupForm {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .sign_up_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .firstLayout {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .firstLayout_input_wrapper {
          width: 48%;
          display: flex;
          flex-direction: column;
          gap: 5px;

          label {
            color: #5b5b5b;
            font-size: 14px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
          }

          input {
            flex-grow: 1;
            color: #0c0c0c;
            font-size: 16px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
            outline: none;
            border: 0.5px solid #5b5b5b;
            padding: 5px 12px;
            margin: 0px;
            border-radius: 8px;

            &::placeholder {
              color: #0c0c0c31;
              font-size: 14px;
            }

            &.error {
              border: 1px solid red;
            }
          }

          .login_content_header_container_password_input_field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #c5c5c5;
            border-radius: 8px;
            padding: 0px 14px;

            &.error {
              border: 1px solid red;
            }

            .login_content_header_container_password_input {
              flex-grow: 1;
              outline: none;
              border: none;
              font-size: 14px;
              font-weight: 400;
              color: #000;
              box-sizing: border-box;

              &::placeholder {
                color: #0c0c0c31;
                font-size: 14px;
              }
            }

            .login_content_header_container_password_input_icon_body {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              .login_content_header_container_password_input_icon {
                font-size: 24px;
                color: #c5c5c5;
              }
            }
          }

          .is-danger {
            margin: 0;
            color: "red";
            font-size: 12px;
            /* font-family: Roboto; */
            font-weight: 300;
            line-height: 30px;
          }

          /* @media screen and (max-width: 1213px) {
            width: 40%;
          } */

          @media screen and (max-width: 740px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 740px) {
          flex-direction: column;
          gap: 10px;
        }
      }

      .secondLayout {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          color: #5b5b5b;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }

        input {
          flex-grow: 1;
          color: #0c0c0c;
          font-size: 16px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
          outline: none;
          border: 0.5px solid #5b5b5b;
          padding: 5px 12px;
          margin: 0px;
          border-radius: 8px;

          &::placeholder {
            color: #0c0c0c31;
            font-size: 14px;
          }

          &.error {
            border: 1px solid red;
          }
        }

        .is-danger {
          margin: 0;
          color: "red";
          font-size: 12px;
          /* font-family: Roboto; */
          font-weight: 300;
          line-height: 30px;
        }
      }
    }

    .state_wrappers {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      .state_wrappers_sign_up {
        button {
          border: none;
          outline: none;
          background-color: #007bff;
          padding: 4px 49px;
          color: white;
          font-size: 16px;
          /* font-family: Roboto; */
          font-weight: 500;
          line-height: 30px;
          border-radius: 20px;
          cursor: pointer;
        }
      }

      .state_wrappers_already_sign_up {
        button {
          border: none;
          outline: none;
          background-color: transparent;
          padding: 0px 10px;
          color: #0c0c0c;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          border-radius: 4px;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      @media screen and (max-width: 450px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
      }
    }

    @media screen and (max-width: 600px) {
      width: 95%;
    }
  }
`;
