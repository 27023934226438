import React from "react";
import { ProgramSectionDiv } from "./styles/programSection.style";
import ProgramImage from "../../Assets/program.svg";

const Programsection = () => {
  return (
    <ProgramSectionDiv>
      <div className="program_head">
        <img src={ProgramImage} className="program_head_program" />
      </div>
      <div className="program_body">
        <div className="program_body_title">
          <p>Program Features</p>
        </div>
        <div className="program_body_content">
          <div className="program_body_content_data">
            <div className="program_body_content_data_box"></div>
            <p>Fully Remote</p>
          </div>

          <div className="program_body_content_data">
            <div className="program_body_content_data_box"></div>
            <p>Hands-on-experience</p>
          </div>

          <div className="program_body_content_data">
            <div className="program_body_content_data_box"></div>
            <p>Collaborative Learning</p>
          </div>

          <div className="program_body_content_data">
            <div className="program_body_content_data_box"></div>
            <p>Real Life Projects</p>
          </div>

          <div className="program_body_content_data">
            <div className="program_body_content_data_box"></div>
            <p>Beginner Friendly</p>
          </div>

          <div className="program_body_content_data">
            <div className="program_body_content_data_box"></div>
            <p>Mentorship</p>
          </div>
        </div>
      </div>
    </ProgramSectionDiv>
  );
};

export default Programsection;
