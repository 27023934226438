import { toast } from "react-toastify";
import APIs from "../apiUrl";

const registerUser = async (payload) => {
  try {
    const response = await APIs.post("/registration", payload);

    if (response?.data?.message === "success") {
      toast("Registered Successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const loginUser = async (payload) => {
  try {
    console.log(payload, "dsss");

    const response = await APIs.post("/login", payload);

    if (response?.data?.message === "success") {
      toast("Login Successfully");

      APIs.defaults.headers[
        "Authorization"
      ] = `Bearer ${response?.data?.data?.token}`;
      localStorage.setItem("token", response?.data?.data?.token);
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const subscribeUser = async (payload) => {
  try {
    const response = await APIs.post("/subscribe", payload);

    console.log(response, "seaseon of work");

    if (response?.data?.message === "success") {
      toast("Subscribe Successfully");

      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const contactSupport = async (payload) => {
  try {
    const response = await APIs.post("/contactus", payload);

    console.log(response, "seaseon of work");

    if (response?.data?.message === "success") {
      toast("sent Successfully");

      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const AuthServices = { registerUser, loginUser, subscribeUser, contactSupport };

export default AuthServices;
