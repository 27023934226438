import { createSlice } from "@reduxjs/toolkit";
import {
  GellAllAssessment,
  GellPendingAssessment,
  GellSingleAssessment,
  SubmitAssessment,
} from "./assessmentServiceSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  allAssessment: [],
  pendingAssessment: [],
  singleAssessmentDetails: {},
  assessmentJustSubmitted: {},
  submitLoader: false,
};

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.allAssessment = [];
      state.pendingAssessment = [];
      state.assessmentJustSubmitted = {};
      state.submitLoader = false;
    },
  },
  extraReducers: {
    [SubmitAssessment.pending]: (state, action) => {
      state.submitLoader = true;
    },

    [SubmitAssessment.fulfilled]: (state, action) => {
      state.submitLoader = false;
      state.message = action.payload?.message;
      state.assessmentJustSubmitted = action.payload.data;
      state.singleAssessmentDetails = {
        ...action?.payload?.data,
        ...state.singleAssessmentDetails,
        status: action.payload.data?.status,
      };
    },

    [SubmitAssessment.rejected]: (state, action) => {
      state.submitLoader = false;
      state.message = action.payload?.message;
    },

    [GellAllAssessment.pending]: (state, action) => {
      state.isLoading = true;
    },

    [GellAllAssessment.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
      state.allAssessment = action.payload.data;
    },

    [GellAllAssessment.rejected]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },

    [GellPendingAssessment.pending]: (state, action) => {
      state.isLoading = true;
    },

    [GellPendingAssessment.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action?.payload?.message;
      state.pendingAssessment = action?.payload?.data;
    },

    [GellPendingAssessment.rejected]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },

    [GellSingleAssessment.pending]: (state, action) => {
      state.isLoading = true;
    },

    [GellSingleAssessment.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
      state.singleAssessmentDetails = action.payload.data;
    },

    [GellSingleAssessment.rejected]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset } = assessmentSlice.actions;
export default assessmentSlice.reducer;
