import React, { useState } from "react";
import { ProfileInformationDiv } from "./styles/profileInformation.styled";
import { useDispatch, useSelector } from "react-redux";
import { editUserPassword } from "../../../features/user/userServiceSync";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";

const Changepassword = () => {
  const dispatch = useDispatch();

  const { userDetails, editLoader } = useSelector((state) => state.user);

  const [formValue, setFormValue] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmitDetails = async (e) => {
    e.preventDefault();

    if (Object.keys(formValue).length === 3) {
      if (
        !formValue?.oldpassword ||
        !formValue?.newPassword ||
        !formValue?.confirmPassword
      ) {
        toast("some field are empty");
      } else {
        if (formValue?.newPassword !== formValue?.confirmPassword) {
          toast("New Password must be equal to confirm New Password");
        } else {
          if (formValue?.newPassword?.length > 6) {
            await dispatch(editUserPassword(formValue));

            setFormValue({});
          } else {
            toast("new Password must be greater than 6");
          }
        }
      }
    } else {
      toast("some field a left");
    }
  };

  return (
    <ProfileInformationDiv>
      <div className="profile_header">
        <p>Change Password</p>
      </div>

      <div className="profile_input_field">
        <div className="profile_input_field_input">
          <div className="profile_input_field_input_fields">
            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Old Password</label>
                <input
                  name="oldpassword"
                  value={formValue?.oldpassword || ""}
                  onChange={handleChange}
                />
              </div>

              {/* <div className="firstLayout_input_wrapper">
                <label>Facebook</label>
                <input name="facebook" />
              </div> */}
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>New Password</label>
                <input
                  name="newPassword"
                  value={formValue?.newPassword || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Confirm New Password</label>
                <input
                  name="confirmPassword"
                  value={formValue?.confirmPassword || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="profile_input_field_button">
          {editLoader ? (
            <InfinitySpin width="150" color="#007bff" />
          ) : (
            <button onClick={handleSubmitDetails}>Update Password</button>
          )}
        </div>
      </div>
    </ProfileInformationDiv>
  );
};

export default Changepassword;
