import { toast } from "react-toastify";
import APIs from "../apiUrl";

const getUserDetails = async () => {
  try {
    const response = await APIs.get("/get_user");

    if (response?.data?.message === "success") {
      toast("fetch user successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const getScoreStateDetails = async () => {
  try {
    const response = await APIs.get("/get_all_user_record");

    if (response?.data?.message === "success") {
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editUserDetails = async (payload) => {
  try {
    const response = await APIs.patch("/edit_user", payload);

    if (response?.data?.message === "success") {
      toast("Edited successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const editUserPassword = async (payload) => {
  try {
    const response = await APIs.patch("/edit_user_password", payload);

    if (response?.data?.message === "success") {
      toast("Edited successfully");
      return response?.data;
    }
  } catch (error) {
    throw error;
  }
};

const UserServices = {
  getUserDetails,
  editUserDetails,
  editUserPassword,
  getScoreStateDetails,
};

export default UserServices;
