import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { DashboardDiv } from "./styles/message.style";
import DashboardCard from "../../components/DashboardComponent/DashboardIndex/dashboardCard";
import Track from "../../components/DashboardComponent/DashboardIndex/track";
import RecentTask from "../../components/DashboardComponent/DashboardIndex/recentTask";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GellPendingAssessment } from "../../features/assessment/assessmentServiceSync";
import { getUserScoresRating } from "../../features/user/userServiceSync";

const Dashboard = () => {
  const dispatch = useDispatch();

  const getToken = localStorage.getItem("token");

  const navigation = useNavigate();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (!getToken) {
      navigation("/signup");

      return;
    }

    dispatch(GellPendingAssessment());
    dispatch(getUserScoresRating());
  }, [getToken]);

  return (
    <Layout>
      <DashboardDiv>
        <div className="dashboard_header">
          <h2>{`Welcome Back, ${userDetails?.firstname} ${userDetails?.lastname}!`}</h2>
          <p>We are Glad to see you again!</p>
        </div>

        <div className="dashbaord_content">
          <DashboardCard />
          <Track />
          <RecentTask />
        </div>
      </DashboardDiv>
    </Layout>
  );
};

export default Dashboard;
