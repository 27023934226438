import { styled } from "styled-components";

export const NavDiv = styled.div`
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px 4px #00000029;
  z-index: 200;
  background-color: #ffffff;
  padding: 10px 0px;
  position: fixed;

  .nav_container {
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .nav_container_fronContainer {
      .nav_container_logo {
        cursor: pointer;

        @media screen and (max-width: 740px) {
        }
      }

      .nav_container_logo_menu {
        @media screen and (min-width: 740px) {
          display: none;
        }

        @media screen and (max-width: 740px) {
          display: block;
        }
      }

      @media screen and (max-width: 740px) {
        width: 95%;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .nav_main_body_wrapper {
      .nav_main_body {
        display: flex;
        align-items: center;
        gap: 40px;

        .nav {
          text-decoration: none;
          color: #000;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 600;
          padding: 5px 12px;

          p {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            color: #0c0c0c;
          }
        }

        .activeNav {
          color: #007bff;
        }

        @media screen and (max-width: 740px) {
          flex-direction: column;
          align-items: unset;
          width: 95%;
          margin: 0 auto;
          gap: 20px;
        }
      }

      @media screen and (max-width: 740px) {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 10px 8px 1px #0000000c;
        display: ${({ show }) => (show ? "block" : "none")};
        padding: 10px 0px 15px 0px;
      }
    }

    @media screen and (max-width: 740px) {
      width: 100%;
      margin: unset;
    }
  }
`;
