import React from "react";
import Layout from "../../utils/layout";
import { ProfileDiv } from "./styles/message.style";
import ProfileComponent from "../../components/DashboardComponent/ProfileComponents";

const Profile = () => {
  return (
    <Layout>
      <ProfileDiv>
        <div className="profile_header">
          <h2>Profile</h2>
          <p>We are Glad to see you again!</p>
        </div>
        <div className="profile_content">
          <ProfileComponent />
        </div>
      </ProfileDiv>
    </Layout>
  );
};

export default Profile;
