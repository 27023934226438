import { createSlice } from "@reduxjs/toolkit";

import {
  LoginUser,
  SubscribeUser,
  contactSupport,
  registerUser,
} from "./authServiceSync";

const initialState = {
  loginDetails: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isRegister: false,
  message: "",
  isRegister: false,
  tokenDetail: localStorage.getItem("token") || "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.isRegister = false;
      state.loginDetails = null;
    },

    updateLoginDetails: (state, action) => {
      state.loginDetails = action.payload;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state, action) => {
      state.isLoading = true;
      state.isRegister = false;
    },

    [registerUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isRegister = true;
      state.message = action.payload?.message;
    },

    [registerUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [LoginUser.pending]: (state, action) => {
      state.isLoading = true;
    },

    [LoginUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
      state.loginDetails = action.payload?.data;
    },

    [LoginUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [SubscribeUser.pending]: (state, action) => {
      state.isLoading = true;
    },

    [SubscribeUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },

    [SubscribeUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },

    [contactSupport.pending]: (state, action) => {
      state.isLoading = true;
    },

    [contactSupport.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload?.message;
    },

    [contactSupport.rejected]: (state, action) => {
      state.isLoading = false;
      state.isRegister = false;
      state.message = action.payload?.message;
    },
  },
});

export const { reset, updateLoginDetails } = authSlice.actions;
export default authSlice.reducer;
