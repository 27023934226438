import React from "react";
import TaskTab from "./taskTab";
import TaskTable from "./taskTable";

const TaskComponent = () => {
  return (
    <div>
      <div>
        <TaskTab />
      </div>

      <div>
        <TaskTable />
      </div>
    </div>
  );
};

export default TaskComponent;
