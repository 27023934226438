import { styled } from "styled-components";

export const ServiceSectionDiv = styled.div`
  padding: 64px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .serviceSection {
    h3 {
      color: black;
      font-size: 24px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .serviceSection_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .serviceSection_content_wrapper {
      width: 31%;
      margin-bottom: 20px;

      .serviceSection_content_wrapper_title {
        p {
          color: #007bff;
          font-size: 18px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }
      }

      .serviceSection_content_wrapper_details {
        .serviceSection_content_wrapper_details_description {
          p {
            color: black;
            font-size: 14px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
          }
        }

        .serviceSection_content_wrapper_details_stack {
          p {
            color: black;
            font-size: 14px;
            /* font-family: Roboto; */
            font-style: italic;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }

      @media screen and (max-width: 980px) {
        width: 45%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
`;
