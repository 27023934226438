import React from "react";
import { NewsLetterDiv } from "./styles/newsLetter.style";
import * as Yup from "yup";
import { SubscribeUser } from "../../features/auth/authServiceSync";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";

const baseSchema = Yup.object({
  email: Yup.string().email().required(),
});

const NewsLetter = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.auth);

  const handleSubmited = async (value, { resetForm }) => {
    // e.preventDefault();

    const payload = {
      ...value,
    };

    await dispatch(SubscribeUser(payload));

    resetForm();
  };

  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  return (
    <NewsLetterDiv>
      <div className="newLetter_title">
        <h3>Subscribe to our News Letter</h3>
      </div>
      <div className="newLetter_form">
        <div className="newLetter_form_input">
          <input
            name="email"
            value={values?.email || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter your email address to subscribe"
            className={`${errors.email ? "error" : ""}`}
          />

          {errors.email && (
            <p className="is-danger" style={{ color: "red" }}>
              {errors.password}
            </p>
          )}
        </div>

        {isLoading ? (
          <Bars
            height="80"
            width="80"
            color="#007bff"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <div className="newLetter_form_button">
            <button onClick={handleSubmit}>Subscribe</button>
          </div>
        )}
      </div>
    </NewsLetterDiv>
  );
};

export default NewsLetter;
