import React, { useContext, useEffect, useState } from "react";
import Layout from "../../utils/layout";
import ChatComponent from "../../components/DashboardComponent/chatComponent";
import { MessageDiv } from "./styles/message.style";
import { AppContext } from "../../context/appContext";
import { useDispatch, useSelector } from "react-redux";

const Message = () => {
  const dispatch = useDispatch();

  const { socket } = useContext(AppContext);

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    socket.emit("join-room", `admin-${userDetails?._id}`);
  }, [userDetails]);

  // socket.off("users-notification").on("users-notification", (roomNotifier) => {
  //   // dispatch({ type: MY_NOTIFICATION_COUNT, payload: roomNotifier });
  // });

  return (
    <Layout>
      <MessageDiv>
        {/* <div className="message_header">
          <h2>Message</h2>
          <p>We are Glad to see you again!</p>
        </div> */}
        <div className="message_chat">
          <ChatComponent />
        </div>
      </MessageDiv>
    </Layout>
  );
};

export default Message;
