import React, { useState } from "react";
import { CreatePostComponentDiv } from "./styles/createpost.style";
import MyEditor from "../../common/myEditor";
import { MdInsertPhoto } from "react-icons/md";
import FormData from "form-data";
import { useDispatch, useSelector } from "react-redux";
import {
  PostBlogger,
  uploadImage,
} from "../../../features/bloggers/bloggersServicesSync";
import { Hearts, LineWave } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateCoverPics } from "../../../features/bloggers/bloggersSlices";

const CreatePostComponent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [loadFile, setLoadFile] = useState();
  const [images, setImages] = useState();
  const [title, setTitle] = useState();
  const [data, setData] = useState();
  const [dataCount, setDataCount] = useState();

  const { contentDetails, isPicLoading, isBlogLoading, articleImageLoader } =
    useSelector((state) => state.blogger);

  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleCoverImage = (e) => {
    const image = e.target.files[0];

    if (image) {
      let promise = getbase64(image);
      promise
        .then((data) => {
          dispatch(uploadImage({ file: data }));
          return data;
        })
        .then((res) => {
          setImages(image?.name);
        });
    }

    // const reader = new FileReader();
    // reader.readAsDataURL(image);

    // reader.onloadend = () => {
    //   console.log(reader.result);
    //   dispatch(uploadImage({ file: reader.result }));
    // };
  };

  // const handleSelectPdf = async (e) => {
  //   e.preventDefault();

  //   setLoadFile(true);
  //   const files = e.target.files[0];

  //   let formData = new FormData();
  //   formData.append("file", files);

  //   console.log(files, "seasons");

  //   dispatch(uploadImage({ formData }));
  //   setLoadFile(false);
  // };

  const handleEditor = (data, count) => {
    setData(data);
    setDataCount(count);
  };

  console.log(contentDetails, "seening it");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPicLoading && !articleImageLoader) {
      if (!title && !data) {
        toast("all field must be filled");
      } else {
        const payload = {
          article: data,
          title: title,
          cover_pic: contentDetails?.secure_url,
          cover_pic_id: contentDetails?.public_id,
          wordCount: dataCount,
        };
        await dispatch(PostBlogger(payload));

        setImages("");
        setData("");
        setTitle("");
        dispatch(updateCoverPics({}));

        navigate("/dashboard/stories");
      }
    } else {
      toast("cover picture is on the way");
    }
  };

  return (
    <CreatePostComponentDiv>
      <div className="createpost_component">
        <div className="createpost_component_header">
          <div className="secondLayout">
            <label>Title of Article</label>
            <textarea
              name="message"
              rows={5}
              value={title || ""}
              onChange={(e) => setTitle(e?.target?.value)}
            ></textarea>
          </div>

          <div className="picture_clone">
            <label>Cover Picture of Article (optional)</label>
            <div className="picture_clone_picker">
              <div className="picture_clone_picker_container">
                <div className="picture_clone_picker_container_icon_body">
                  <MdInsertPhoto className="picture_clone_picker_container_icon" />
                </div>

                <input
                  type="file"
                  onChange={(e) => handleCoverImage(e)}
                  className="picture_clone_picker_container_file"
                />
              </div>

              {isPicLoading ? (
                <LineWave
                  height="100"
                  width="100"
                  color="#007bff"
                  ariaLabel="line-wave"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  firstLineColor=""
                  middleLineColor=""
                  lastLineColor=""
                />
              ) : (
                <p className="picture_clone_picker_content">
                  {images ? images : <>click the icon to select a picture</>}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="createpost_component_post">
          <label>Content of The Article</label>
          <MyEditor data={data} handleEditor={handleEditor} />
        </div>
      </div>

      <div className="create__Post__container">
        {isBlogLoading ? (
          <Hearts
            height="80"
            width="80"
            color="#007bff"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <button className="create__Post" onClick={handleSubmit}>
            Create Post
          </button>
        )}
      </div>
    </CreatePostComponentDiv>
  );
};

export default CreatePostComponent;
