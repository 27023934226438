import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import ShowView from "../../components/DashboardComponent/showView";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleBlogger } from "../../features/bloggers/bloggersServicesSync";
import { useParams } from "react-router-dom";
import LoaderScreen from "../../utils/loaderScreen";

const ViewCreatedPost = () => {
  const dispatch = useDispatch();

  const { singleStoryPageLoader } = useSelector((state) => state.blogger);

  const { singlePostId } = useParams();

  useEffect(() => {
    dispatch(GetSingleBlogger(singlePostId));
  }, []);

  return (
    <Layout>{singleStoryPageLoader ? <LoaderScreen /> : <ShowView />}</Layout>
  );
};

export default ViewCreatedPost;
