import React from "react";
import { ServiceSectionDiv } from "./styles/serviceSection.style";
import { ServiceSectionArray } from "../../utils/serviceSection";

const ServiceSection = () => {
  return (
    <ServiceSectionDiv>
      <div className="serviceSection">
        <h3>What you’ll learn</h3>
      </div>
      <div className="serviceSection_content">
        {ServiceSectionArray?.map((item, key) => (
          <div className="serviceSection_content_wrapper">
            <div className="serviceSection_content_wrapper_title">
              <p>{item?.title}</p>
            </div>
            <div className="serviceSection_content_wrapper_details">
              <div className="serviceSection_content_wrapper_details_description">
                <p>{item?.text}</p>
              </div>
              <div className="serviceSection_content_wrapper_details_stack">
                <p>{item?.stack}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ServiceSectionDiv>
  );
};

export default ServiceSection;
