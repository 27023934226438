import React from "react";
import { StoriesTabDiv } from "./styles/storiesTab.styles";
import { useDispatch, useSelector } from "react-redux";
import { updateStoreTab } from "../../../features/bloggers/bloggersSlices";
import {
  GetBlogger,
  GetMyBlogger,
} from "../../../features/bloggers/bloggersServicesSync";

const StoriesTab = () => {
  const dispatch = useDispatch();

  const { storyTab, ordersLimit, mylimit, articleDetails, myArticleDetails } =
    useSelector((state) => state.blogger);

  const handleChangeTab = (tab) => {
    dispatch(updateStoreTab(tab));

    if (tab === "all") {
      if (articleDetails?.length === 0) {
        dispatch(GetBlogger(ordersLimit));
      }
    } else {
      if (myArticleDetails?.length === 0) {
        dispatch(GetMyBlogger(mylimit));
      }
    }
  };

  return (
    <StoriesTabDiv>
      <div className="storiesTab">
        <div className="storiesTab_container">
          <button
            className={`${storyTab === "all" ? "selected" : ""}`}
            onClick={() => handleChangeTab("all")}
          >
            All Stories
          </button>
        </div>
        <div className="storiesTab_container">
          <button
            className={`${storyTab === "my" ? "selected" : ""}`}
            onClick={() => handleChangeTab("my")}
          >
            My Stories
          </button>
        </div>
      </div>
    </StoriesTabDiv>
  );
};

export default StoriesTab;
