import { styled } from "styled-components";

export const NewsLetterDiv = styled.div`
  text-align: center;
  padding: 60px 0px;
  flex-direction: column;
  display: flex;
  gap: 35px;
  width: 100%;

  .newLetter_title {
    h3 {
      color: black;
      font-size: 24px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }
  }

  .newLetter_form {
    width: 50%;
    margin: 0 auto;
    flex-direction: column;
    display: flex;
    align-items: center;
    gap: 30px;

    .newLetter_form_input {
      width: 80%;

      input {
        width: 80%;
        color: #0c0c0c;
        font-size: 16px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
        outline: none;
        border: 0.5px solid #5b5b5b;
        padding: 10px 20px;

        &.error {
          border: 1px solid red;
        }

        &::placeholder {
          color: #0c0c0c31;
          font-size: 14px;
        }
      }

      .is-danger {
        margin: 0;
        color: "red";
        font-size: 12px;
        /* font-family: Roboto; */
        font-weight: 300;
        line-height: 30px;
      }
    }

    .newLetter_form_button {
      button {
        border: 1px solid #007bff;
        border-radius: 4px;
        outline: none;
        background-color: transparent;
        padding: 6px 49px;
        color: #007bff;
        font-size: 14px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 920px) {
      width: 80%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`;
