import React from "react";
import { ProfileComponentDiv } from "./styles/profileComponent.style";
import ProfileInformation from "./profileInformation";
import ProfileSocialMedia from "./profileSocialMedia";
import Changepassword from "./changepassword";

const ProfileComponent = () => {
  return (
    <ProfileComponentDiv>
      <div>
        <ProfileInformation />
      </div>
      <div>
        <ProfileSocialMedia />
      </div>
      <div>
        <Changepassword />
      </div>
    </ProfileComponentDiv>
  );
};

export default ProfileComponent;
