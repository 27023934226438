import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllBlogger,
  GetAllBloggerAdvanced,
  GetBlogger,
  GetBloggerAdvanced,
  GetMyBlogger,
  GetMyBloggerAdvanced,
  GetSingleBlogger,
  PostBlogger,
  uploadImage,
} from "./bloggersServicesSync";

const initialState = {
  isError: false,
  isSuccess: false,
  isPicLoading: false,
  isBlogLoading: false,
  articleImageLoader: false,
  message: "",
  allMessage: "",
  contentDetails: {},
  articleDetails: [],
  myArticleDetails: [],
  allArticleDetails: [],
  mylimit: 10,
  myPagination: 0,
  ordersLimit: 10,
  orderPagination: 0,
  myBloggersCount: 0,
  ordersBloggersCount: 0,
  allLimit: 10,
  allPagination: 0,
  allBloggersCount: 0,
  averageLimit: 10,
  storiesPageLoad: false,
  storyTab: "all",
  singleStoryPageLoader: false,
  singleStoryPageDetails: {},
};

export const userSlice = createSlice({
  name: "blogger",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isPicLoading = false;
      state.isSuccess = false;
      state.isBlogLoading = false;
      state.message = "";
      state.articleImageLoader = false;
      state.mylimit = 10;
      state.message = 0;
      state.ordersLimit = 10;
      state.orderPagination = 0;
      state.myBloggersCount = 0;
      state.ordersBloggersCount = 0;
      state.storiesPageLoad = false;
      state.storyTab = "all";
      state.articleDetails = [];
      state.myArticleDetails = [];
      state.singleStoryPageLoader = false;
      state.singleStoryPageDetails = {};
      state.allPagination = 0;
      state.allBloggersCount = 0;
      state.allMessage = "";
      state.allArticleDetails = [];
      state.allLimit = 10;
    },

    checkMessage: (state, action) => {
      state.message = action.payload;
    },

    updataeSingleStoryPageLoader: (state, action) => {
      state.singleStoryPageLoader = action.payload;
    },

    checkImageDetails: (state, action) => {
      state.imageDetails = action.payload;
    },

    checkLoader: (state, action) => {
      state.articleImageLoader = action.payload;
    },

    updateCoverPics: (state, action) => {
      state.contentDetails = action.payload;
    },

    updateMyLimit: (state, action) => {
      state.mylimit = action.payload;
    },

    updateAllLimit: (state, action) => {
      state.allLimit = action.payload;
    },

    updateMyPagination: (state, action) => {
      state.myPagination = action.payload;
    },

    updateAllPagination: (state, action) => {
      state.allPagination = action.payload;
    },

    updateOrderLimit: (state, action) => {
      state.ordersLimit = action.payload;
    },

    updateOrderPagination: (state, action) => {
      state.orderPagination = action.payload;
    },

    updateMyBloggerCount: (state, action) => {
      state.myBloggersCount = action.payload;
    },

    updateOrderBloggerCount: (state, action) => {
      state.ordersBloggersCount = action.payload;
    },

    updateStoriesPageLoader: (state, action) => {
      state.storiesPageLoad = action.payload;
    },

    updateStoreTab: (state, action) => {
      state.storyTab = action.payload;
    },
  },
  extraReducers: {
    [uploadImage.pending]: (state, action) => {
      state.isPicLoading = true;
    },

    [uploadImage.fulfilled]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
      state.contentDetails = action.payload?.data;
    },

    [uploadImage.rejected]: (state, action) => {
      state.isPicLoading = false;
      state.message = action.payload?.message;
    },

    [PostBlogger.pending]: (state, action) => {
      state.isBlogLoading = true;
    },

    [PostBlogger.fulfilled]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [PostBlogger.rejected]: (state, action) => {
      state.isBlogLoading = false;
      state.message = action.payload?.message;
    },

    [GetBlogger.pending]: (state, action) => {
      state.storiesPageLoad = true;
    },

    [GetBlogger.fulfilled]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
      state.articleDetails = action?.payload?.data;
      state.ordersBloggersCount = action?.payload?.count;
    },

    [GetBlogger.rejected]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetBloggerAdvanced.pending]: (state, action) => {
      state.storiesPageLoad = false;
    },

    [GetBloggerAdvanced.fulfilled]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
      state.orderPagination = state.orderPagination + 1;
      state.ordersLimit = state.ordersLimit + state.averageLimit;
      state.articleDetails = [...action?.payload?.data];
      state.ordersBloggersCount = action?.payload?.count;
    },

    [GetBloggerAdvanced.rejected]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyBlogger.pending]: (state, action) => {
      state.storiesPageLoad = true;
    },

    [GetMyBlogger.fulfilled]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
      state.myArticleDetails = action?.payload?.data;
      state.myBloggersCount = action?.payload?.count;
    },

    [GetMyBlogger.rejected]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetMyBloggerAdvanced.pending]: (state, action) => {
      state.storiesPageLoad = false;
    },

    [GetMyBloggerAdvanced.fulfilled]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
      state.myPagination = state.myPagination + 1;
      state.mylimit = state.mylimit + state.averageLimit;
      state.myArticleDetails = [...action?.payload?.data];
      state.myBloggersCount = action?.payload?.count;
    },

    [GetMyBloggerAdvanced.rejected]: (state, action) => {
      state.storiesPageLoad = false;
      state.message = action.payload?.message;
    },

    [GetSingleBlogger.pending]: (state, action) => {
      state.singleStoryPageLoader = true;
    },

    [GetSingleBlogger.fulfilled]: (state, action) => {
      state.singleStoryPageLoader = false;
      state.message = action.payload?.message;
      state.singleStoryPageDetails = action.payload.data;
    },

    [GetSingleBlogger.rejected]: (state, action) => {
      state.singleStoryPageLoader = false;
      state.message = action.payload?.message;
    },

    [GetAllBlogger.pending]: (state, action) => {
      state.storiesPageLoad = true;
    },

    [GetAllBlogger.fulfilled]: (state, action) => {
      state.storiesPageLoad = false;
      state.allMessage = action.payload?.message;
      state.allArticleDetails = action?.payload?.data;
      state.allBloggersCount = action?.payload?.count;
    },

    [GetAllBlogger.rejected]: (state, action) => {
      state.storiesPageLoad = false;
      state.allMessage = action.payload?.message;
    },

    [GetAllBloggerAdvanced.pending]: (state, action) => {
      state.storiesPageLoad = false;
    },

    [GetAllBloggerAdvanced.fulfilled]: (state, action) => {
      state.storiesPageLoad = false;
      state.allMessage = action.payload?.message;
      state.allPagination = state.allPagination + 1;
      state.allLimit = state.allLimit + state.averageLimit;
      state.allArticleDetails = [...action?.payload?.data];
      state.allBloggersCount = action?.payload?.count;
    },

    [GetAllBloggerAdvanced.rejected]: (state, action) => {
      state.storiesPageLoad = false;
      state.allMessage = action.payload?.message;
    },
  },
});

export const {
  reset,
  checkMessage,
  checkImageDetails,
  checkLoader,
  updateOrderLimit,
  updateMyLimit,
  updateMyBloggerCount,
  updateOrderBloggerCount,
  updateStoriesPageLoader,
  updateCoverPics,
  updateMyPagination,
  updateOrderPagination,
  updateStoreTab,
  updataeSingleStoryPageLoader,
  updateAllLimit,
  updateAllPagination,
} = userSlice.actions;

export default userSlice.reducer;
