import styled from "styled-components";

export const ProfileInformationDiv = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* width: 100%; */

  .profile_header {
    width: 15%;

    p {
      margin: 0;
      color: #101828;
      font-size: 18px;
      /* font-family: Roboto; */
      font-weight: 400;
      line-height: 30px;
    }

    @media screen and (max-width: 740px) {
      width: 100%;
    }
  }

  .profile_input_field {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .profile_input_field_input {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .profile_input_field_input_image {
        .profile_input_field_input_image_avatar {
          position: relative;
          width: 260px;
          height: 270px;

          .profile_input_field_input_image_avartar_card {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .profile_input_field_input_image_avatar_upload_card {
            position: absolute;
            left: 20px;
            bottom: 30px;
            border: 1px solid red;
            display: flex;
            align-items: center;
            background-color: #ffffff;
            border-radius: 8px;
            padding: 5px 10px;
            gap: 10px;
            cursor: pointer;

            .profile_input_field_input_image_avatar_upload_card_icon_body {
              display: flex;
              align-items: center;
              justify-content: center;

              .profile_input_field_input_image_avatar_upload_card_icon {
                color: #ff8f7e;
                font-size: 24px;
              }
            }

            p {
              margin: 0;
              color: #101828;
              font-size: 14px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
            }
          }

          @media screen and (max-width: 740px) {
            width: 200px;
            height: 210px;
          }
        }
      }

      .profile_input_field_input_fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .firstLayout {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .firstLayout_input_wrapper {
            width: 48%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
              color: #5b5b5b;
              font-size: 14px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
            }

            input {
              flex-grow: 1;
              color: #0c0c0c;
              font-size: 16px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
              outline: none;
              border: 0.5px solid #ced4da;
              padding: 5px 12px;
              margin: 0px;
              border-radius: 8px;
              /* background-color: #f5f6f4; */
            }

            @media screen and (max-width: 740px) {
              width: 100%;
            }
          }

          @media screen and (max-width: 740px) {
            flex-direction: column;
            gap: 10px;
          }
        }

        .secondLayout {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;

          label {
            color: #5b5b5b;
            font-size: 14px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
          }

          textarea {
            flex-grow: 1;
            color: #0c0c0c;
            font-size: 16px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
            outline: none;
            border: 0.5px solid #ced4da;
            padding: 5px 12px;
            margin: 0px;
            border-radius: 8px;
            resize: none;
            /* background-color: #f5f6f4; */
          }

          input {
            flex-grow: 1;
            color: #0c0c0c;
            font-size: 16px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
            outline: none;
            border: 0.5px solid #ced4da;
            padding: 5px 12px;
            margin: 0px;
            border-radius: 8px;

            &::placeholder {
              color: #0c0c0c31;
              font-size: 14px;
            }

            &.error {
              border: 1px solid red;
            }
          }

          .is-danger {
            margin: 0;
            color: "red";
            font-size: 12px;
            /* font-family: Roboto; */
            font-weight: 300;
            line-height: 30px;
          }
        }
      }
    }

    .profile_input_field_button {
      display: flex;
      justify-content: flex-end;

      button {
        border: none;
        outline: none;
        background-color: #007bff;
        padding: 4px 49px;
        color: white;
        font-size: 16px;
        /* font-family: Roboto; */
        font-weight: 500;
        line-height: 30px;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 740px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
    gap: 30px;
  }
`;
