import styled from "styled-components";

export const ModalStyle = styled.div`
  z-index: 50;
  padding: 120px 0px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(52, 64, 84, 0.25);
  backdrop-filter: blur(0px);

  .modalContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }
`;
