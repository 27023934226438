import { styled } from "styled-components";

export const BlogDiv = styled.div`
  width: 100%;
  padding: 60px 0px;
  padding-top: 100px;

  .blog {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .blog_header {
      h2 {
        color: #000;
        font-size: 32px;
        /* font-family: Roboto; */
        font-weight: 600;
        line-height: 30px;
        margin: 0;
      }
    }

    .blog_Details {
      display: flex;
      flex-direction: column;
      gap: 50px;
      width: 70%;

      @media screen and (max-width: 920px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 920px) {
      width: 95%;
    }
  }
`;

export const ShowBlogDiv = styled.div`
  width: 100%;
  padding: 60px 0px;

  .blog {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .blog_header {
      h2 {
        color: #000;
        font-size: 32px;
        /* font-family: Roboto; */
        font-weight: 600;
        line-height: 30px;
        margin: 0;
      }
    }

    .blog_Details {
      display: flex;
      flex-direction: column;
      gap: 50px;
      width: 70%;

      @media screen and (max-width: 920px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 920px) {
      width: 95%;
    }
  }
`;
