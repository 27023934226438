import React, { useEffect, useState } from "react";
import { ProfileInformationDiv } from "./styles/profileInformation.styled";
import { useDispatch, useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { editUser } from "../../../features/user/userServiceSync";

const ProfileSocialMedia = () => {
  const dispatch = useDispatch();

  const { userDetails, editLoader } = useSelector((state) => state.user);

  const [formValue, setFormValue] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    setFormValue({
      ...formValue,
      my_website: userDetails?.my_website,
      facebook: userDetails?.facebook,
      linkedln: userDetails?.linkedln,
      twitter: userDetails?.twitter,
      github: userDetails?.github,
    });
  }, [userDetails]);

  const handleSubmitDetails = (e) => {
    e.preventDefault();

    if (Object.keys(formValue).length > 0) {
      dispatch(editUser(formValue));
    } else {
      toast("some field a left");
    }
  };
  return (
    <ProfileInformationDiv>
      <div className="profile_header">
        <p>Social Media</p>
      </div>

      <div className="profile_input_field">
        <div className="profile_input_field_input">
          <div className="profile_input_field_input_fields">
            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>My Website</label>
                <input
                  name="my_website"
                  value={formValue?.my_website || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Facebook</label>
                <input
                  name="facebook"
                  value={formValue?.facebook || ""}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Linkedln</label>
                <input
                  name="linkedln"
                  value={formValue?.linkedln || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Twitter</label>
                <input
                  name="twitter"
                  value={formValue?.twitter || ""}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>github</label>
                <input
                  name="github"
                  value={formValue?.github || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="profile_input_field_button">
          {editLoader ? (
            <InfinitySpin width="150" color="#007bff" />
          ) : (
            <button onClick={handleSubmitDetails}>
              Update Social Media Info
            </button>
          )}
        </div>
      </div>
    </ProfileInformationDiv>
  );
};

export default ProfileSocialMedia;
