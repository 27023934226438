import { styled } from "styled-components";

export const AboutUsDiv = styled.div`
  width: 100%;
  padding: 60px 0px;
  padding-top: 100px;

  .about_us_header {
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px 40px 0px;

    .about_us_header_content {
      width: 48%;

      display: flex;
      flex-direction: column;
      gap: 30px;

      p {
        width: 100%;
        color: #5b5b5b;
        font-size: 18px;
        /* font-family: Roboto; */
        font-weight: 600;
        line-height: 30px;
        margin: 0;
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    .about_us_header_pics {
      width: 40%;

      .about_us_header_pics_image {
        width: 100%;
      }

      @media screen and (max-width: 920px) {
        width: 48%;
      }

      @media screen and (max-width: 740px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 740px) {
      width: 95%;
      flex-direction: column;
      gap: 30px;
    }
  }

  .about_us_vision_mission {
    background-color: #f9f9f9;
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;

    .about_us_vision {
      text-align: center;
      width: 25%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      h2 {
        width: 100%;
        color: #5b5b5b;
        font-size: 24px;
        /* font-family: Roboto; */
        font-weight: 700;
        line-height: 30px;
        margin: 0;
      }

      p {
        width: 100%;
        color: #5b5b5b;
        font-size: 18px;
        /* font-family: Roboto; */
        font-weight: 500;
        line-height: 30px;
        margin: 0;
      }

      @media screen and (max-width: 1380px) {
        width: 40%;
      }

      @media screen and (max-width: 740px) {
        width: 70%;
      }
    }
  }

  .about_us_contact_us {
    padding: 30px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;

    p {
      color: #5b5b5b;
      font-size: 20px;
      /* font-family: Roboto; */
      font-weight: 700;
      line-height: 30px;
      margin: 0;
    }

    button {
      border: none;
      outline: none;
      background-color: #007bff;
      padding: 4px 49px;
      color: white;
      font-size: 16px;
      /* font-family: Roboto; */
      font-weight: 500;
      line-height: 30px;
      border-radius: 20px;
      cursor: pointer;
    }

    @media screen and (max-width: 740px) {
      width: 70%;
      margin: 0 auto;
    }
  }
`;
