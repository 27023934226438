import React, { useEffect } from "react";
import { MessageDiv } from "./styles/message.style";
import SingleAssessmentComponent from "../../components/DashboardComponent/singleAssessment";
import Layout from "../../utils/layout";
import { useDispatch, useSelector } from "react-redux";
import { GellSingleAssessment } from "../../features/assessment/assessmentServiceSync";
import { useParams } from "react-router-dom";
import MiniLoader from "../../utils/miniLoader";

const SingleAssessment = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.assessment);

  const { taskId } = useParams();

  useEffect(() => {
    dispatch(GellSingleAssessment(taskId));
  }, [taskId]);

  return (
    <Layout>
      <MessageDiv>
        <div className="message_header">
          <h2>Single Task</h2>
        </div>
        {isLoading ? (
          <MiniLoader />
        ) : (
          <div className="message_chat">
            <SingleAssessmentComponent />
          </div>
        )}
      </MessageDiv>
    </Layout>
  );
};

export default SingleAssessment;
