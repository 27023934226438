import React, { useState } from "react";
import { ProfileInformationDiv } from "./styles/profileInformation.styled";
import ProfileAvatar from "../../../Assets/images/avatar-profile-photo.png";
import { FiUploadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editUser } from "../../../features/user/userServiceSync";
import { EditedLoader } from "../../../features/user/userSlices";
import { InfinitySpin } from "react-loader-spinner";

const ProfileInformation = () => {
  const dispatch = useDispatch();

  const { userDetails, editLoader } = useSelector((state) => state.user);

  const [formValue, setFormValue] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmitDetails = (e) => {
    e.preventDefault();

    if (Object.keys(formValue).length === 3) {
      if (
        !formValue?.country ||
        !formValue?.employment ||
        !formValue?.about_me
      ) {
        toast("some field are empty");
      } else {
        dispatch(editUser(formValue));
      }
    } else {
      toast("some field a left");
    }
  };

  return (
    <ProfileInformationDiv>
      <div className="profile_header">
        <p>Profile Information</p>
      </div>

      <div className="profile_input_field">
        <div className="profile_input_field_input">
          <div className="profile_input_field_input_image">
            <div className="profile_input_field_input_image_avatar">
              <img
                src={ProfileAvatar}
                className="profile_input_field_input_image_avartar_card"
              />

              <div className="profile_input_field_input_image_avatar_upload_card">
                <div className="profile_input_field_input_image_avatar_upload_card_icon_body">
                  <FiUploadCloud className="profile_input_field_input_image_avatar_upload_card_icon" />
                </div>
                <p>Upload Photo</p>
              </div>
            </div>
          </div>
          <div className="profile_input_field_input_fields">
            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>First Name</label>
                <input
                  name="firstname"
                  value={userDetails?.firstname}
                  disabled
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Last Name</label>
                <input name="lastname" value={userDetails?.lastname} disabled />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Email</label>
                <input name="email" value={userDetails.email} disabled />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Username</label>
                <input name="username" value={userDetails.username} disabled />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Age</label>
                <input name="age" value={userDetails.age} disabled />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Phone Number</label>
                <input
                  name="phone_number"
                  value={userDetails?.phone_number}
                  disabled
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Track</label>
                <input name="track" value={userDetails?.track} disabled />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Gender</label>
                <input name="gender" value={userDetails?.gender} disabled />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Experience Level</label>
                <input
                  name="experience_level"
                  value={userDetails?.experience_level}
                  disabled
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Education Level</label>
                <input
                  name="educational_level"
                  value={userDetails?.educational_level}
                  disabled
                />
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Country</label>
                <input
                  name="country"
                  value={
                    userDetails?.country
                      ? userDetails?.country
                      : formValue?.country || ""
                  }
                  onChange={handleChange}
                  disabled={userDetails?.country ? true : false}
                />
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Employment Status</label>
                <input
                  name="employment"
                  value={
                    userDetails?.employment
                      ? userDetails?.employment
                      : formValue?.employment || ""
                  }
                  onChange={handleChange}
                  disabled={userDetails?.employment ? true : false}
                />
              </div>
            </div>

            <div className="secondLayout">
              <label>Address</label>
              <input name="address" value={userDetails?.address} disabled />
            </div>

            <div className="secondLayout">
              <label>About me</label>
              <textarea
                name="about_me"
                value={
                  userDetails?.about_me
                    ? userDetails?.about_me
                    : formValue?.about_me || ""
                }
                onChange={handleChange}
                disabled={userDetails?.about_me ? true : false}
                rows={5}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="profile_input_field_button">
          {editLoader ? (
            <InfinitySpin width="150" color="#007bff" />
          ) : (
            <button onClick={handleSubmitDetails}>Update Profile Info</button>
          )}
        </div>
      </div>
    </ProfileInformationDiv>
  );
};

export default ProfileInformation;
