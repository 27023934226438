import React, { useEffect } from "react";
import { BlogDiv } from "./styles/blog.styles";
import BlogCard from "../components/common/Stories/storiesCard";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllBlogger,
  GetAllBloggerAdvanced,
} from "../features/bloggers/bloggersServicesSync";
import { InfinitySpin } from "react-loader-spinner";
import OutSideLoaderScreen from "../utils/outSideLoader";

const Blog = () => {
  const dispatch = useDispatch();

  const {
    storiesPageLoad,
    allBloggersCount,
    averageLimit,
    allArticleDetails,
    allPagination,
    allLimit,
  } = useSelector((state) => state.blogger);

  useEffect(() => {
    dispatch(GetAllBlogger(allLimit));
  }, []);

  return (
    <BlogDiv>
      <div className="blog">
        <div className="blog_header">
          <h2>View Student Stories</h2>
        </div>

        {!storiesPageLoad ? (
          <InfiniteScroll
            pageStart={allPagination}
            loadMore={async () => {
              if (allBloggersCount > allArticleDetails.length) {
                dispatch(GetAllBloggerAdvanced(allLimit + averageLimit));
              }
            }}
            hasMore={true || false}
            loader={
              allBloggersCount > allArticleDetails.length && (
                <InfinitySpin width="200" color="#007bff" />
              )
            }
          >
            <div className="blog_Details">
              <BlogCard cardArry={allArticleDetails} />
            </div>
          </InfiniteScroll>
        ) : (
          <OutSideLoaderScreen />
        )}
      </div>
    </BlogDiv>
  );
};

export default Blog;
