import React from "react";
import { ContactDiv } from "./styles/contact.style";
import ContactPic from "../Assets/images/contact.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { contactSupport } from "../features/auth/authServiceSync";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";

const baseSchema = Yup.object({
  name: Yup.string().required(),
  subject: Yup.string().required(),
  message: Yup.string().required(),
  email: Yup.string().email().required(),
});

const Contact = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.auth);

  const handleSubmited = async (value, { resetForm }) => {
    // e.preventDefault();

    const payload = {
      ...value,
      gender: value?.gender?.toLowerCase(),
      experience_level: value?.experience_level?.toLowerCase(),
    };

    await dispatch(contactSupport(payload));

    resetForm();
  };

  const { values, handleBlur, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      name: "",
      message: "",
      subject: "",
      email: "",
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  return (
    <ContactDiv>
      <div className="contact">
        <div className="contact_header">
          <h2>Contact Us</h2>
        </div>
        <div className="contact_content">
          <div className="contact_content_pic">
            <img src={ContactPic} />
          </div>

          <form className="contact_content_form" onSubmit={handleSubmit}>
            <div className="contact_content_form_content">
              <div className="firstLayout">
                <div className="firstLayout_input_wrapper">
                  <label>Name</label>
                  <input
                    name="name"
                    value={values?.name || ""}
                    onBlur={handleBlur}
                    placeholder="Enter Your Name"
                    onChange={handleChange}
                    className={`${errors.name && "error"}`}
                  />
                </div>

                <div className="firstLayout_input_wrapper">
                  <label>Email</label>
                  <input
                    name="email"
                    value={values?.email || ""}
                    onBlur={handleBlur}
                    placeholder="Enter Your Email"
                    onChange={handleChange}
                    className={`${errors.email && "error"}`}
                  />
                </div>
              </div>

              <div className="secondLayout">
                <label>Subject</label>
                <input
                  name="subject"
                  value={values?.subject || ""}
                  onBlur={handleBlur}
                  placeholder="Enter Your Subject"
                  onChange={handleChange}
                  className={`${errors.subject && "error"}`}
                />
              </div>

              <div className="secondLayout">
                <label>Message</label>
                <textarea
                  name="message"
                  value={values?.message || ""}
                  onBlur={handleBlur}
                  placeholder="Enter Your Name"
                  onChange={handleChange}
                  rows={5}
                  className={`${errors.message && "error"}`}
                ></textarea>
              </div>
            </div>

            <div className="state_wrappers">
              <div className="state_wrappers_sign_up">
                {isLoading ? (
                  <Bars
                    height="80"
                    width="80"
                    color="#007bff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <button>Sign Up</button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </ContactDiv>
  );
};

export default Contact;
