import React, { useEffect, useState } from "react";
import { FaHive, FaMagic, FaSignal, FaSitemap, FaTasks } from "react-icons/fa";
import { DashboardCardDiv } from "./styles/dashboardCard.style";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Dna } from "react-loader-spinner";

const CardArray = [
  { icon: FaSitemap, title: "Total Stages", count: 0 },
  { icon: FaTasks, title: "Current Stage", count: 0 },
  { icon: FaSignal, title: "Stage Total Point", count: 0 },
  { icon: FaMagic, title: "Point Scored", count: 0 },
];

const DashboardCard = () => {
  const [cards, setCards] = useState(CardArray);

  const { scoreArray, issearchingLoading } = useSelector((state) => state.user);

  console.log(scoreArray, "seeamej");

  // useEffect(() => {
  //   const mapedData = cards.map((item) => {
  //     if (item?.title === "Current Stage") {
  //       item["count"] = scoreDetails.stage;
  //     }

  //     if (item?.title === "Total Stages") {
  //       item["count"] = scoreDetails?.total_stage;
  //     }

  //     if (item?.title === "Point Scored") {
  //       item["count"] = scoreDetails?.point_scores;
  //     }

  //     if (item?.title === "Stage Total Point") {
  //       if (scoreDetails.stage === 1) {
  //         item["count"] = scoreDetails?.stage_one_total;
  //       }

  //       if (scoreDetails.stage === 2) {
  //         item["count"] = scoreDetails?.stage_two_total;
  //       }

  //       if (scoreDetails.stage === 3) {
  //         item["count"] = scoreDetails?.stage_three_total;
  //       }

  //       if (scoreDetails.stage === 4) {
  //         item["count"] = scoreDetails?.stage_four_total;
  //       }

  //       if (scoreDetails.stage === 5) {
  //         item["count"] = scoreDetails?.stage_five_total;
  //       }
  //     }
  //   });

  //   setCards(mapedData);
  // }, [scoreDetails]);

  return (
    <DashboardCardDiv>
      {!issearchingLoading ? (
        <>
          {scoreArray?.map((item, key) => (
            <div className="cardComponent" key={key}>
              <div className="cardComponent_content">
                <p className="cardComponent_content_title">{item?.title}</p>
                <p className="cardComponent_content_score">
                  {item?.count ? item?.count : 0}
                </p>
              </div>
              <div className="cardComponent_icon_body">
                {/* <item.icon className="cardComponent_icon" /> */}
              </div>
            </div>
          ))}
        </>
      ) : (
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      )}
    </DashboardCardDiv>
  );
};

export default DashboardCard;
