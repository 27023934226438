import LandingPage from "./screens/landingpage";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Login from "./screens/login";
import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./screens/dashboard";
import SideBar from "./components/DashboardComponent/sideBar";
import TopNavBar from "./components/DashboardComponent/TopBar";
import Message from "./screens/dashboard/message";
import Stories from "./screens/dashboard/stories";
import Signup from "./screens/signup";
import Aboutus from "./screens/aboutus";
import Contact from "./screens/contact";
import Blog from "./screens/blog";
import Createpost from "./screens/dashboard/createpost";
import Task from "./screens/dashboard/task";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./screens/dashboard/profile";
import DashboardProtectedRoute from "./protected/DashboardProtectedRoute";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import ViewCreatedPost from "./screens/dashboard/viewCreatedPost";
import ViewBlog from "./screens/viewBlog";
import SingleAssessment from "./screens/dashboard/singleAssessment";
// import "./App.css";

function App() {
  const location = useLocation();

  return (
    <>
      {!location.pathname.includes("dashboard") && <Nav />}

      {location.pathname.includes("dashboard") && <SideBar />}

      {location.pathname.includes("dashboard") && <TopNavBar />}

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about_us" element={<Aboutus />} />
        <Route path="/contact_us" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<ViewBlog />} />

        <Route element={<DashboardProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/message" element={<Message />} />
          <Route path="/dashboard/stories" element={<Stories />} />
          <Route path="/dashboard/create_post" element={<Createpost />} />
          <Route path="/dashboard/task" element={<Task />} />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route
            path="/dashboard/:singlePostId"
            element={<ViewCreatedPost />}
          />

          <Route
            path="/dashboard/task/:taskId"
            element={<SingleAssessment />}
          />
        </Route>
        <Route
          path="*"
          element={
            <div>
              <p>404</p>
            </div>
          }
        />
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={3500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        toastStyle={{
          backgroundColor: "#007bff",
          color: "#ffffff",
          fontWeight: "600",
          fontSize: "16px",
        }}
      />

      {!location.pathname.includes("dashboard") && <Footer />}
    </>
  );
}

export default App;
