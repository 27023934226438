import React, { useEffect, useState } from "react";
import { SignupDiv } from "./styles/sigup.style";
import { useTimer } from "react-timer-hook";
import moment from "moment";
import { Watch, Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../features/auth/authServiceSync";
import * as Yup from "yup";
import { useFormik } from "formik";
import SuccessModal from "../components/common/successModal";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import "./styles/signupcss.css";
import { classNames } from "primereact/utils";

import {
  BsFillEyeFill,
  BsFillPersonFill,
  BsFillEyeSlashFill,
} from "react-icons/bs";

const Gender = [{ name: "Male" }, { name: "Female" }];

const Experience = [
  { name: "Beginner Level" },
  { name: "Intermediate Level" },
  { name: "Advanced Level" },
];

const toLowerCaseValidator = (value) => {
  return value.toLowerCase();
};

const baseSchema = Yup.object({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  username: Yup.string().required(),
  age: Yup.string().required(),
  email: Yup.string().email().required(),
  address: Yup.string().required(),
  educational_level: Yup.string().required(),
  about_us: Yup.string().required(),
  phone_number: Yup.string().required(),
  track: Yup.string().required(),
  password: Yup.string().min(6).required(),
  comfirm_password: Yup.string()
    .min(6)
    .required()
    .oneOf([Yup.ref("password"), null]),

  gender: Yup.string().required(),

  experience_level: Yup.string().required(),

  // gender: Yup.string()
  //   .required("Please input a gender which will either be a MALE or FEMALE")
  //   .transform(toLowerCaseValidator)
  //   .oneOf(Gender),

  // experience_level: Yup.string()
  //   .required(
  //     "Experience Level must either be beginner level, intermediate level or advanced level"
  //   )
  //   .transform(toLowerCaseValidator)
  //   .oneOf(Experience),
});

const Signup = () => {
  const dispatch = useDispatch();
  const [expire, setExpire] = useState(false);

  const [selectedCity, setSelectedCity] = useState();

  const navigation = useNavigate();

  const getToken = localStorage.getItem("token");

  const [formValue, setFormValue] = useState({
    track: "Front-End Developer",
  });

  const [showPassword, setShowPassword] = useState(false);

  const { showSignUp, setShowSignUp } = useState(false);

  const { isRegister, isLoading } = useSelector((state) => state.auth);

  // Create a Date object for the current date and time
  // const currentDate = new Date();

  // Set the desired date to October 20, 2023
  const targetDate = new Date("2023-11-1");

  let dtime = moment(targetDate);

  const { seconds, minutes, hours, days, pause } = useTimer({
    expiryTimestamp: dtime,
    autoStart: true,
    onExpire: () => {
      pause();
      setExpire(true);
      // if (statusResponse?.status === "pending") {
      //   dispatch(cancelTradeByIDAction({trade_id: id}));
      // }
    },
  });

  const handleSubmited = async (value, { resetForm }) => {
    // e.preventDefault();

    const payload = {
      ...value,
      gender: value?.gender?.toLowerCase(),
      experience_level: value?.experience_level?.toLowerCase(),
    };

    await dispatch(registerUser(payload));

    resetForm();
  };

  const {
    values,
    field,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      username: "",
      age: "",
      email: "",
      address: "",
      educational_level: "",
      about_us: "",
      phone_number: "",
      track: "Front-End Developer",
      password: "",
      comfirm_password: "",
      gender: "",
      experience_level: "",
    },
    validationSchema: baseSchema,
    onSubmit: handleSubmited,
  });

  useEffect(() => {
    if (getToken) {
      navigation("/dashboard");
    }
  }, [getToken]);

  const isFormFieldInvalid = (name) => !!(touched[name] && errors[name]);

  console.log(values, field, errors, "senidfn works");

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <SignupDiv>
      <div className="sign_up">
        <div className="sign_up_contract">
          <div className="sign_up_contract_registration">
            <h3>
              Kick start your tech career by registering with us for the 2023
              Techpaver Training. Be sure to fill in your details with a valid
              email and a working phone nunber. This is to ensure that you are
              invited to the workspace.
            </h3>

            <h3 className="seesa">
              They program begins 1st of November, get yourself ready.
            </h3>
          </div>

          <div className="sign_up_contract_registration_2">
            <Watch
              height="40"
              width="40"
              radius="48"
              color="#007bff"
              ariaLabel="watch-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <h2>
              We Have
              {` ${days}Days ${hours}:${minutes}:${seconds}`} To Go.
            </h2>
          </div>
        </div>

        <form className="signupForm" onSubmit={handleSubmit}>
          <div className="sign_up_container">
            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>First Name*</label>
                <input
                  name="firstname"
                  value={values?.firstname || ""}
                  onBlur={handleBlur}
                  placeholder="Enter Your First Name"
                  onChange={handleChange}
                  className={`${errors.firstname ? "error" : ""}`}
                />
                {errors.firstname && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.firstname}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Last Name*</label>
                <input
                  name="lastname"
                  value={values?.lastname || ""}
                  placeholder="Enter Your Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.lastname ? "error" : ""}`}
                />
                {errors.lastname && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.lastname}
                  </p>
                )}
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Username*</label>
                <input
                  name="username"
                  value={values?.username || ""}
                  placeholder="Enter Your Username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.username ? "error" : ""}`}
                />

                {errors.username && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.username}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Age*</label>
                <input
                  name="age"
                  value={values?.age || ""}
                  placeholder="Enter Your Age"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.age ? "error" : ""}`}
                />

                {errors.age && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.age}
                  </p>
                )}
              </div>
            </div>

            <div className="secondLayout">
              <label>Email Address*</label>
              <input
                name="email"
                value={values?.email || ""}
                placeholder="Enter Your Email"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${errors.email ? "error" : ""}`}
              />

              {errors.email && (
                <p className="is-danger" style={{ color: "red" }}>
                  {errors.email}
                </p>
              )}
            </div>

            <div className="secondLayout">
              <label>Address*</label>
              <input
                name="address"
                value={values?.address || ""}
                placeholder="Enter Your Address"
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${errors.address ? "error" : ""}`}
              />

              {errors.address && (
                <p className="is-danger" style={{ color: "red" }}>
                  {errors.address}
                </p>
              )}
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Educational Level*</label>
                <input
                  name="educational_level"
                  value={values?.educational_level || ""}
                  placeholder="Enter Educational Level"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.educational_level ? "error" : ""}`}
                />

                {errors.educational_level && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.educational_level}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>How did you hear About us*</label>

                <input
                  name="about_us"
                  value={values?.about_us || ""}
                  placeholder="Enter How did you hear About us"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.about_us ? "error" : ""}`}
                />

                {errors.about_us && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.about_us}
                  </p>
                )}
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Phone Number*</label>
                <input
                  name="phone_number"
                  value={values?.phone_number || ""}
                  placeholder="Enter Your Phone Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.phone_number ? "error" : ""}`}
                />

                {errors.phone_number && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.phone_number}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Track*</label>
                <input
                  name="track"
                  value={"Front-End Developer"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.track ? "error" : ""}`}
                />

                {errors.track && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.track}
                  </p>
                )}
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Gender*</label>
                {/* <input
                  name="gender"
                  value={values?.gender || ""}
                  onChange={handleChange}
                  placeholder="This Field should either be Male or Female"
                  onBlur={handleBlur}
                  className={`${errors.gender ? "error" : ""}`}
                /> */}

                <Dropdown
                  value={{ name: values?.gender }}
                  onChange={(e) => {
                    setFieldValue("gender", e.value.name);
                  }}
                  options={Gender}
                  optionLabel="name"
                  placeholder="Select a Gender"
                  className={
                    ("dropdown",
                    classNames({
                      "p-invalid": isFormFieldInvalid("experience_level"),
                    }))
                  }
                />

                {errors.gender && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.gender}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Experience Level*</label>
                <Dropdown
                  value={{ name: values?.experience_level }}
                  onChange={(e) => {
                    console.log(e.value.name, "sending of working");
                    setFieldValue("experience_level", e.value.name);
                  }}
                  options={Experience}
                  optionLabel="name"
                  placeholder="Select a Experience Level"
                  className={
                    ("dropdown",
                    classNames({
                      "p-invalid": isFormFieldInvalid("experience_level"),
                    }))
                  }
                />

                {errors.experience_level && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.experience_level}
                  </p>
                )}
              </div>
            </div>

            <div className="firstLayout">
              <div className="firstLayout_input_wrapper">
                <label>Password*</label>
                {/* <input
                  type="password"
                  name="password"
                  value={values?.password || ""}
                  onChange={handleChange}
                  placeholder="Enter Password"
                  onBlur={handleBlur}
                  className={`${errors.password ? "error" : ""}`}
                /> */}

                <div
                  className={`login_content_header_container_password_input_field ${
                    errors.password ? "error" : ""
                  }`}
                >
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    value={values?.password || ""}
                    placeholder="Enter Password"
                    onBlur={handleBlur}
                    className={`login_content_header_container_password_input `}
                  />

                  {!showPassword ? (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeFill className="login_content_header_container_password_input_icon" />
                    </div>
                  ) : (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeSlashFill className="login_content_header_container_password_input_icon" />
                    </div>
                  )}
                </div>

                {errors.password && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.password}
                  </p>
                )}
              </div>

              <div className="firstLayout_input_wrapper">
                <label>Comfirm Password*</label>
                {/* <input
                  type="password"
                  name="comfirm_password"
                  value={values?.comfirm_password || ""}
                  placeholder="Enter The Correct Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`${errors.comfirm_password ? "error" : ""}`}
                /> */}

                <div
                  className={`login_content_header_container_password_input_field ${
                    errors.password ? "error" : ""
                  }`}
                >
                  <input
                    name="comfirm_password"
                    type={showPassword ? "text" : "password"}
                    onChange={handleChange}
                    value={values?.comfirm_password || ""}
                    placeholder="Enter Password"
                    onBlur={handleBlur}
                    className={`login_content_header_container_password_input `}
                  />

                  {!showPassword ? (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeFill className="login_content_header_container_password_input_icon" />
                    </div>
                  ) : (
                    <div
                      className="login_content_header_container_password_input_icon_body"
                      onClick={handleShowPassword}
                    >
                      <BsFillEyeSlashFill className="login_content_header_container_password_input_icon" />
                    </div>
                  )}
                </div>

                {errors.comfirm_password && (
                  <p className="is-danger" style={{ color: "red" }}>
                    {errors.comfirm_password}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="state_wrappers">
            {isLoading ? (
              <Bars
                height="80"
                width="80"
                color="#007bff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <div className="state_wrappers_sign_up">
                <button type="submit">Sign Up</button>
              </div>
            )}
            {/* <div className="state_wrappers_already_sign_up">
            <button>Already have and account</button>
          </div> */}
          </div>
        </form>
      </div>

      {isRegister && <SuccessModal />}
    </SignupDiv>
  );
};

export default Signup;
