import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { MiniLoaderScreenDiv } from "./styles/layout.styles";

const MiniLoader = () => {
  return (
    <MiniLoaderScreenDiv>
      <CirclesWithBar
        height="100"
        width="100"
        color="#007bff"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        outerCircleColor=""
        innerCircleColor=""
        barColor=""
        ariaLabel="circles-with-bar-loading"
      />
    </MiniLoaderScreenDiv>
  );
};

export default MiniLoader;
