import React, { useState } from "react";
import { SingleAssessmentDiv } from "./styles/singleAssessment.style";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import { Editor } from "primereact/editor";
import { SubmitAssessment } from "../../../features/assessment/assessmentServiceSync";
import ThreeLoader from "../../common/threeLoader";
import { toast } from "react-toastify";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdDangerous } from "react-icons/md";

const SingleAssessmentComponent = () => {
  const dispatch = useDispatch();

  const { singleAssessmentDetails, submitLoader } = useSelector(
    (state) => state.assessment
  );

  const [data, setData] = useState("");

  const [counter, setCounter] = useState(0);

  const handleEditor = (data, count) => {
    setData(data);

    setCounter(count);
  };

  const handleSubmitAssessment = async () => {
    if (!data) {
      toast("No Answer Found");

      return;
    }

    if (Object.keys(singleAssessmentDetails).length > 5) {
      if (
        singleAssessmentDetails?.date ||
        singleAssessmentDetails?.createdAt ||
        singleAssessmentDetails?.updatedAt
      ) {
        const payload = {
          ...singleAssessmentDetails,
          beginning_date: singleAssessmentDetails?.createdAt,
          ending_date: singleAssessmentDetails?.time_status,
          assessmentId: singleAssessmentDetails?._id,
          answer: data,
        };

        // delete singleAssessmentDetails?.date;
        // delete singleAssessmentDetails?.createdAt;
        // delete singleAssessmentDetails?.updatedAt;

        await dispatch(SubmitAssessment(payload));

        setData("");
      }
    }
  };

  return (
    <SingleAssessmentDiv>
      <div className="assessment">
        <div className="assessment_header">
          <div className="assessment_header_container">
            <p>
              {`Submission deadline: ${moment(
                singleAssessmentDetails?.time_status
              ).format("MMM DD, YYYY LT")}`}{" "}
              <span style={{ fontWeight: 700, fontSize: "18px" }}>
                <Countdown
                  date={singleAssessmentDetails?.time_status}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <span>{`${days}:${hours}:${minutes}:${seconds}`}</span>
                  )}
                />
              </span>
            </p>
          </div>
          <div className="assessment_header_container">
            <p>{`Task points: ${singleAssessmentDetails?.total_mark}`}</p>
          </div>
        </div>
        <div className="assessment_title">
          <h3>{`Task Title: ${singleAssessmentDetails?.title}`}</h3>
          <p>{singleAssessmentDetails?.course}</p>
          <p>{`Created At ${moment(singleAssessmentDetails?.createdAt).format(
            "MMM DD, YYYY LT"
          )}`}</p>
        </div>

        <div className="assessment_description">
          <p>Submit done when you are done</p>
        </div>

        <div className="assessment_description_wrapper">
          {singleAssessmentDetails?.assessment_questions?.map((item, key) => (
            <div key={key} className="assessment_description_container">
              <div className="assessment_description_container_wrapper">
                <p>{`${key + 1}.`}</p>
              </div>
              <div className="assessment_description_watch_wrapper">
                <div className="assessment_description_watch">
                  <h3>{item?.title}</h3>
                  <p>{item?.mark}</p>
                </div>
                <p>{HTMLReactParser(HTMLReactParser(item?.content))}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="assessment_status">
          <h3>Task Status</h3>
          <p>{singleAssessmentDetails?.status}</p>
        </div>
      </div>

      {singleAssessmentDetails?.status === "pending" ? (
        <div className="task">
          <div className="task_wrapper">
            <div className="task_header">
              <h3>Submit Task</h3>
            </div>

            <div className="task_header_content">
              <h3>mode of submission</h3>

              <p>Enter the solution to the task in the input field given:</p>
            </div>

            <div className="task_header_task">
              <p>Enter Answer:</p>
              {/* <MyEditor handleEditor={handleEditor} data={data} /> */}
              <Editor
                value={data}
                onTextChange={(e) => {
                  setData(e.htmlValue);
                }}
                //   style={{ height: "320px" }}
              />
            </div>
          </div>

          <div className="task_wrapper_buttons">
            <button
              onClick={handleSubmitAssessment}
              disabled={submitLoader ? true : false}
            >
              {submitLoader ? <ThreeLoader /> : <>Submit</>}
            </button>
          </div>
        </div>
      ) : singleAssessmentDetails?.status === "submitted" ? (
        <div className="task_state">
          <div className="wrapperUnpending">
            <h3>{singleAssessmentDetails?.status}</h3>
            <div className="wrapperUnpending_icon_body">
              <BsFillCheckCircleFill className="wrapperUnpending_icon" />
            </div>
          </div>
        </div>
      ) : (
        <div className="task_state_danger">
          <div className="wrapperUnpending">
            <h3>{singleAssessmentDetails?.status}</h3>
            <div className="wrapperUnpending_icon_body">
              <MdDangerous className="wrapperUnpending_icon" />
            </div>
          </div>
        </div>
      )}
    </SingleAssessmentDiv>
  );
};

export default SingleAssessmentComponent;
