import React, { useEffect } from "react";
import TaskComponent from "../../components/DashboardComponent/Task.js";
import Layout from "../../utils/layout.js";
import { TaskDiv } from "./styles/message.style.js";
import { GellAllAssessment } from "../../features/assessment/assessmentServiceSync.js";
import { useDispatch } from "react-redux";

const Task = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GellAllAssessment());
  }, []);

  return (
    <Layout>
      <TaskDiv>
        <div className="task_header">
          <h2>Task</h2>
          <p>We are Glad to see you again!</p>
        </div>
        <div className="task_content">
          <TaskComponent />
        </div>
      </TaskDiv>
    </Layout>
  );
};

export default Task;
