import { configureStore } from "@reduxjs/toolkit";
import utitilitiesReducer from "../features/utilities/utilitiesSlices";
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/user/userSlices";
import BloggerReducer from "../features/bloggers/bloggersSlices";
import assessmentReducer from "../features/assessment/assessmentSlice";

export const store = configureStore({
  reducer: {
    utitilities: utitilitiesReducer,
    auth: authReducer,
    user: userReducer,
    blogger: BloggerReducer,
    assessment: assessmentReducer,
  },
});
