const { styled } = require("styled-components");

export const EnrollForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0px;

  .contact_content_pic {
    width: 48%;

    img {
      object-fit: cover;
    }

    @media screen and (max-width: 740px) {
      width: 100%;
    }
  }

  .enroll_content {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .enroll_content_title {
      p {
        color: black;
        font-size: 24px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
        margin: 0;
      }
    }

    .enroll_content_details {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .enroll_content_details_content {
        p {
          margin: 0;
          color: #5b5b5b;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .enroll_form {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .enroll_form_name {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .enroll_form_name_lable {
        color: #5b5b5b;
        font-size: 14px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
      }

      .enroll_form_name_input {
        flex-grow: 1;
        color: #0c0c0c;
        font-size: 16px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
        outline: none;
        border: 0.5px solid #5b5b5b;
        padding: 5px 12px;
        margin: 0px;
        border-radius: 8px;
      }
    }

    .enroll_form_two_form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .enroll_form_two_form_one {
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .enroll_form_two_form_one_label {
          color: #5b5b5b;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }

        .enroll_form_two_form_one_input {
          flex-grow: 1;
          color: #0c0c0c;
          font-size: 16px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
          outline: none;
          border: 0.5px solid #5b5b5b;
          padding: 5px 12px;
          margin: 0px;
          border-radius: 8px;
        }

        @media screen and (max-width: 1213px) {
          width: 40%;
        }

        @media screen and (max-width: 740px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 740px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .enroll_form_button {
      button {
        border: none;
        outline: none;
        background-color: #007bff;
        padding: 4px 49px;
        color: white;
        font-size: 16px;
        /* font-family: Roboto; */
        font-weight: 500;
        line-height: 30px;
        border-radius: 4px;
      }
    }

    @media screen and (max-width: 740px) {
      gap: 10px;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;
