import { createAsyncThunk } from "@reduxjs/toolkit";
import AssessmentService from "./assessmentServices";
import { toast } from "react-toastify";
import moment from "moment";

export const SubmitAssessment = createAsyncThunk(
  "assessment/submit_assessment",
  async (payload) => {
    try {
      let response = await AssessmentService.submitAssessment(payload);

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GellAllAssessment = createAsyncThunk(
  "assessment/allAssessment",
  async () => {
    try {
      let response = await Promise.all([
        AssessmentService.getAllAssessment(),
        AssessmentService.getAllUsersSubmittedAssessment(),
      ])
        .then((data) => {
          return data;
        })
        .catch((err) => {
          throw err;
        });

      async function Solvethis() {
        const allAssessments = { ...response[0] };

        const submittedAssessment = { ...response[1] };

        for (let item of allAssessments?.data) {
          const today = moment().add(0, "day");

          const statedDate = moment(item?.time_status);

          const timeChecker = today?.diff(statedDate, "minute");

          if (submittedAssessment?.data?.length > 0) {
            submittedAssessment?.data.forEach((data) => {
              if (item?._id === data?.assessmentId) {
                const index = allAssessments?.data?.findIndex(
                  (res) => res._id === item?._id
                );

                allAssessments.data[index] = {
                  ...data,
                  ...allAssessments.data[index],
                  status: data?.status,
                };
              }
            });

            const index = allAssessments?.data?.findIndex(
              (res) => res._id === item?._id
            );

            if (!allAssessments.data[index]?.score) {
              allAssessments.data[index] = {
                ...allAssessments?.data[index],
                status:
                  timeChecker > 0
                    ? "not submitted"
                    : allAssessments?.data[index]?.status,
                score: 0,
              };
            }
          } else {
            const index = allAssessments?.data?.findIndex(
              (res) => res?._id === item?._id
            );

            allAssessments.data[index] = {
              ...allAssessments?.data[index],
              status:
                timeChecker > 0
                  ? "not submitted"
                  : allAssessments?.data[index]?.status,
              score: 0,
            };
          }
        }

        return allAssessments;
      }

      const handleSort = await Solvethis();

      return handleSort;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GellPendingAssessment = createAsyncThunk(
  "assessment/pendingAssessment",
  async () => {
    try {
      let response = await Promise.all([
        AssessmentService.getPendingAssessment(),
        AssessmentService.getAllUsersSubmittedAssessment(),
      ])
        .then((data) => {
          return data;
        })
        .catch((err) => {
          throw err;
        });

      async function Solvethis() {
        const pendingAssessments = { ...response[0] };

        const submittedAssessment = { ...response[1] };

        for (let item of pendingAssessments?.data) {
          const today = moment().add(0, "day");

          const statedDate = moment(item?.time_status);

          const timeChecker = today?.diff(statedDate, "minute");

          if (submittedAssessment?.data?.length > 0) {
            submittedAssessment?.data?.forEach((data, ind) => {
              if (item?._id === data?.assessmentId) {
                const index = pendingAssessments?.data?.findIndex(
                  (res) => res._id === item?._id
                );

                pendingAssessments.data[index] = {
                  ...data,
                  ...pendingAssessments.data[index],
                  status: data?.status,
                };
              }

              if (submittedAssessment?.data?.length - 1 === ind) {
              }
            });

            const index = pendingAssessments?.data?.findIndex(
              (res) => res._id === item?._id
            );

            if (!pendingAssessments.data[index]?.score) {
              pendingAssessments.data[index] = {
                ...pendingAssessments?.data[index],
                status:
                  timeChecker > 0
                    ? "not submitted"
                    : pendingAssessments?.data[index]?.status,
                score: 0,
              };
            }
          } else {
            const index = pendingAssessments?.data?.findIndex(
              (res) => res?._id === item?._id
            );

            pendingAssessments.data[index] = {
              ...pendingAssessments?.data[index],
              status:
                timeChecker > 0
                  ? "not submitted"
                  : pendingAssessments?.data[index]?.status,
              score: 0,
            };
          }
        }

        return pendingAssessments;
      }

      const handleSort = await Solvethis();

      return handleSort;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GellSingleAssessment = createAsyncThunk(
  "assessment/singleAssessment",
  async (payload) => {
    try {
      let response = await Promise.all([
        AssessmentService.getSingleAssessment(payload),
        AssessmentService.getSinglesUsersSubmittedAssessment(payload),
      ])
        .then((data) => {
          return data;
        })
        .catch((err) => {
          throw err;
        });

      async function Solvethis() {
        const today = moment().add(0, "day");

        const statedDate = moment(response[0]?.data?.time_status);

        const timeChecker = today?.diff(statedDate, "minute");

        if (response[1]?.data !== null) {
          const singleAssessments = { ...response[0] };

          const submittedAssessment = { ...response[1] };

          return {
            data: {
              ...submittedAssessment?.data,
              ...singleAssessments?.data,
              status: submittedAssessment?.data?.status,
            },
            message: singleAssessments?.message,
          };
        } else {
          return {
            data: {
              ...response[0]?.data,
              status:
                timeChecker > 0 ? "not submitted" : response[0]?.data?.status,
            },

            message: response[0]?.message,
          };
        }
      }

      const handleSort = await Solvethis();

      return handleSort;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);

export const GellAlUserSubmittedAssessment = createAsyncThunk(
  "assessment/all_submitted_Assessment",
  async () => {
    try {
      let response = await AssessmentService.getAllUsersSubmittedAssessment();

      return response;
    } catch (error) {
      toast(error.message);
      throw error;
    }
  }
);
