import { styled } from "styled-components";

export const FooterDiv = styled.div`
  width: 100%;
  background-color: #f9f9f9;

  .footer_header {
    width: 85%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0px;
    flex-wrap: wrap;
    gap: 15px;

    .footer_header_one {
      width: 31%;
      flex-direction: column;
      display: flex;
      gap: 30px;

      .footer_header_one_title {
        h2 {
          color: #3e3c3c;
          font-size: 24px;
          /* font-family: Roboto; */
          font-weight: 700;
          line-height: 30px;
        }
      }

      .footer_header_one_body {
        p {
          margin: 0px;
          color: black;
          font-size: 14px;
          /* font-family: Roboto; */
          font-weight: 400;
          line-height: 30px;
        }
      }

      @media screen and (max-width: 850px) {
        width: 45%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .footer_header_two {
      width: 31%;
      flex-direction: column;
      display: flex;
      gap: 30px;

      p {
        color: #3e3c3c;
        font-size: 16px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
      }

      .footer_header_two_body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .footer_header_two_body_blog {
          display: flex;
          align-items: center;
          gap: 12px;

          img {
            width: 85px;
            height: 80px;
          }

          .footer_header_two_body_blog_body {
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            .footer_header_two_body_blog_body_title {
              margin: 0px;
              color: #3e3c3c;
              font-size: 14px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
            }

            .footer_header_two_body_blog_body_date {
              margin: 0px;
              color: #3e3c3c;
              font-size: 14px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
            }
          }
        }
      }

      @media screen and (max-width: 850px) {
        width: 45%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .footer_header_three {
      width: 31%;
      flex-direction: column;
      display: flex;
      gap: 30px;

      p {
        color: #3e3c3c;
        font-size: 16px;
        /* font-family: Roboto; */
        font-weight: 400;
        line-height: 30px;
      }

      .footer_header_three_description {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .footer_header_three_description_body {
          display: flex;
          align-items: center;
          gap: 24px;

          .footer_header_three_description_body_icon {
            display: flex;
            align-items: center;
            justify-content: center;

            .footer_header_three_description_body_icons {
              font-size: 24px;
              color: black;
            }
          }

          p {
            color: #3e3c3c;
            font-size: 14px;
            /* font-family: Roboto; */
            font-weight: 400;
            line-height: 30px;
            margin: 0px;
          }
        }
      }

      @media screen and (max-width: 850px) {
        width: 45%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 740px) {
      width: 95%;
    }
  }

  .footer_bottom {
    width: 100%;
    background-color: #007bff;

    .footer_bottom_container {
      width: 85%;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0px;

      .footer_bottom_training {
        p {
          color: white;
          font-size: 14px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 30px;
        }
      }

      .footer_bottom_wrapper {
        display: flex;
        align-items: center;
        gap: 25px;

        .footer_bottom_wrapper_icon_body {
          display: flex;
          align-items: center;
          justify-content: center;

          .footer_bottom_wrapper_icon {
            font-size: 20px;
            color: white;
          }
        }
      }

      @media screen and (max-width: 740px) {
        width: 95%;
      }

      @media screen and (max-width: 400px) {
        flex-direction: column;
      }
    }
  }
`;
