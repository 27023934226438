import styled from "styled-components";

export const SuccessModalDiv = styled.div`
  text-align: center;
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;

  .comfirmation__firstLayout {
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .comfirmation__firstLayoutImageBody {
      width: 150px;
      height: 150px;
      border-radius: 50%;

      .comfirmation__firstLayoutImage {
        width: 100%;
        border: none;
      }
    }
  }

  .comfirmation__secondLayout {
    h3 {
      /* font-family: "Inter"; */
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
      padding: 5px 0px;
    }

    h2 {
      color: #344054;
      font-size: 36px;
      /* font-family: "inter"; */
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 32px;
    }

    p {
      /* font-family: "Inter"; */
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
    }
  }

  .comfirmation__thirdLayout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px 20px 0px;

    .comfirmation__thirdLayoutWallet {
      width: 48%;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      /* font-family: "Inter"; */
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #344054;
      height: 40px;
      cursor: pointer;
    }

    .comfirmation__thirdLayoutHistory {
      width: 100%;
      background: #007bff;
      border: none;
      outline: none;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      /* font-family: "Inter"; */
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
      height: 40px;
    }
  }

  .comfirmation__thirdLayout_center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px 20px 0px;

    .comfirmation__thirdLayoutHistory {
      width: 100%;
      background: #007bff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      /* font-family: "Inter"; */
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
      height: 40px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;
