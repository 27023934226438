import { styled } from "styled-components";

export const ContactDiv = styled.div`
  width: 100%;
  padding: 60px 0px;
  padding-top: 100px;

  .contact {
    width: 80%;
    margin: 0 auto;

    .contact_header {
      text-align: center;
      padding: 20px 0px;

      h2 {
        color: #5b5b5b;
        font-size: 32px;
        /* font-family: Roboto; */
        font-weight: 600;
        line-height: 30px;
        margin: 0;
      }
    }

    .contact_content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .contact_content_pic {
        width: 48%;

        img {
          object-fit: cover;
        }

        @media screen and (max-width: 740px) {
          width: 100%;
        }
      }

      .contact_content_form {
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .contact_content_form_content {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 15px;

          .firstLayout {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .firstLayout_input_wrapper {
              width: 48%;
              display: flex;
              flex-direction: column;
              gap: 5px;

              label {
                color: #5b5b5b;
                font-size: 14px;
                /* font-family: Roboto; */
                font-weight: 400;
                line-height: 30px;
              }

              input {
                flex-grow: 1;
                color: #0c0c0c;
                font-size: 16px;
                /* font-family: Roboto; */
                font-weight: 400;
                line-height: 30px;
                outline: none;
                border: 0.5px solid #ced4da;
                padding: 5px 12px;
                margin: 0px;
                border-radius: 8px;
                background-color: #f5f6f4;

                &.error {
                  border: 1px solid red;
                }
              }

              @media screen and (max-width: 740px) {
                width: 100%;
              }
            }

            @media screen and (max-width: 740px) {
              flex-direction: column;
              gap: 10px;
            }
          }

          .secondLayout {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            label {
              color: #5b5b5b;
              font-size: 14px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
            }

            input {
              flex-grow: 1;
              color: #0c0c0c;
              font-size: 16px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
              outline: none;
              border: 0.5px solid #ced4da;
              padding: 5px 12px;
              margin: 0px;
              border-radius: 8px;
              background-color: #f5f6f4;

              &.error {
                border: 1px solid red;
              }
            }

            @media screen and (max-width: 740px) {
              width: 100%;
            }

            textarea {
              flex-grow: 1;
              color: #0c0c0c;
              font-size: 16px;
              /* font-family: Roboto; */
              font-weight: 400;
              line-height: 30px;
              outline: none;
              border: 0.5px solid #ced4da;
              padding: 5px 12px;
              margin: 0px;
              border-radius: 8px;
              background-color: #f5f6f4;
              resize: none;

              &.error {
                border: 1px solid red;
              }
            }
          }
        }
        .state_wrappers {
          .state_wrappers_sign_up {
            button {
              border: none;
              outline: none;
              background-color: #007bff;
              padding: 4px 49px;
              color: white;
              font-size: 16px;
              /* font-family: Roboto; */
              font-weight: 500;
              line-height: 30px;
              border-radius: 20px;
              cursor: pointer;
            }
          }
        }

        @media screen and (max-width: 740px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 740px) {
        flex-direction: column;
      }
    }

    @media screen and (max-width: 740px) {
      width: 95%;
    }
  }
`;
