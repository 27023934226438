import React from "react";
import HeroSection from "../components/LandingPage/heroSection";
import {
  LandingPageDiv,
  LandingPageDivWithBg,
} from "./styles/landingpage.styles";
import AboutUs from "../components/LandingPage/aboutUs";
import Programsection from "../components/LandingPage/programsection";
import ServiceSection from "../components/LandingPage/serviceSection";
import EnrolForm from "../components/LandingPage/enrolForm";
import NewsLetter from "../components/LandingPage/newsLetter";

const LandingPage = () => {
  return (
    <>
      <LandingPageDiv>
        <HeroSection />

        <AboutUs />

        <Programsection />
      </LandingPageDiv>

      <LandingPageDivWithBg>
        <div className="withBg">
          <ServiceSection />
        </div>
      </LandingPageDivWithBg>

      <LandingPageDiv>
        <EnrolForm />
        <NewsLetter />
      </LandingPageDiv>
      <div></div>
    </>
  );
};

export default LandingPage;
