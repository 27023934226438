import React, { useEffect } from "react";
import Layout from "../../utils/layout";
import { StoriesDiv } from "./styles/message.style";
import StoriesComponent from "../../components/DashboardComponent/Stories";
import { useDispatch, useSelector } from "react-redux";
import { GetBlogger } from "../../features/bloggers/bloggersServicesSync";
import { reset } from "../../features/bloggers/bloggersSlices";

const Stories = () => {
  const dispatch = useDispatch();

  const { ordersLimit } = useSelector((state) => state.blogger);

  useEffect(() => {
    dispatch(GetBlogger(ordersLimit));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Layout>
      <StoriesDiv>
        <div className="stories_header">
          <h2>Stories Page</h2>
          <p>We are Glad to see you again!</p>
        </div>

        <div className="stories_content">
          <StoriesComponent />
        </div>
      </StoriesDiv>
    </Layout>
  );
};

export default Stories;
