import React from "react";
import { AboutUsDiv } from "./styles/about.style";
import Pics from "../Assets/images/pexels-christina-morillo-1181273.jpg";
import { useNavigate } from "react-router-dom";

const Aboutus = () => {
  const navigate = useNavigate();

  return (
    <AboutUsDiv>
      <div className="about_us_header">
        <div className="about_us_header_content">
          <p>
            TechPaver is a tech institute that aims to teach andenlighten
            everyone about tech and how to createtheir own product
          </p>

          <p>
            TechPaver is designed to support capacity development in the field
            of information and communication technology (ICT) by providing
            training, mentorship, leadership, and career opportunities to young
            Nigerians.
          </p>
        </div>
        <div className="about_us_header_pics">
          <img src={Pics} className="about_us_header_pics_image" />
        </div>
      </div>
      <div className="about_us_vision_mission">
        <div className="about_us_vision">
          <h2>Our Vision</h2>
          <p>
            To be the best and most reliable Mentor to upcoming and pro
            techenthusiasts in and beyond Africa
          </p>
        </div>
        <div className="about_us_vision">
          <h2>Our Mission</h2>
          <p>
            To create a community of tech enthusiasts and individuals, looking
            to learn,expand and acquire a skill in software development and
            other tech related skills.
          </p>
        </div>
      </div>
      <div className="about_us_contact_us">
        <p>We are available to answer your questions.</p>
        <button onClick={() => navigate("/contact_us")}>Contact Us</button>
      </div>
    </AboutUsDiv>
  );
};

export default Aboutus;
