import React from "react";
import { SideBarDiv } from "./styles/sidebar.style";
import mainImage from "../../../Assets/images/avatar-profile-photo.svg";
import { FiBarChart2, FiHome } from "react-icons/fi";
import { BsFillChatQuoteFill } from "react-icons/bs";
import { FaBloggerB, FaFileContract } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ResetNavBar } from "../../../features/utilities/utilitiesSlices";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { showNavBar } = useSelector((state) => state.utitilities);

  const { userDetails } = useSelector((state) => state.user);

  return (
    <SideBarDiv content={showNavBar}>
      <div className="sidebarConatiner">
        <div className="header">
          <NavLink to={`/`}>
            <h2>Techpaver</h2>
          </NavLink>

          <div
            className="header_body"
            onClick={() => dispatch(ResetNavBar(false))}
          >
            <MdOutlineClose className="header_icon" />
          </div>
        </div>
        <div className="sidebar_body">
          {SideBarArray?.map((item, key) => (
            <div
              key={key}
              className={`sidebar_body_content ${
                location?.pathname === item?.path ? "Selected" : ""
              }`}
              onClick={() => navigate(`${item?.path}`)}
            >
              <div className="sidebar_body_icon_body">
                <item.icon
                  fontSize={25}
                  color={"#FAFAFA"}
                  stroke="#FFFFFF"
                  aria-hidden
                  className="sidebar_body_icon"
                />
              </div>
              <p className="sidebar_body_text">{item?.page}</p>
            </div>
          ))}
        </div>
        <div className="bottom">
          <img src={mainImage} alt="profile" />
          <div className="subbottom">
            {userDetails?.username ? (
              <h3>{userDetails?.username}</h3>
            ) : (
              <SkeletonTheme baseColor="#2A29296E" highlightColor="#444">
                <p>
                  <Skeleton width="8rem" height="1.5rem" />
                </p>
              </SkeletonTheme>
            )}

            {userDetails?.email ? (
              <p>{userDetails?.email}</p>
            ) : (
              <SkeletonTheme baseColor="#2A29296E" highlightColor="#444">
                <p>
                  <Skeleton width="8rem" height="1.5rem" />
                </p>
              </SkeletonTheme>
            )}
          </div>
        </div>
      </div>
    </SideBarDiv>
  );
};

const SideBarArray = [
  {
    icon: FiHome,
    page: "Dashboard",
    path: "/dashboard",
    index: 1,
  },
  {
    icon: BsFillChatQuoteFill,
    page: "Message",
    path: "/dashboard/message",
    index: 2,
  },
  {
    icon: FaBloggerB,
    page: "Create Blog",
    path: "/dashboard/create_post",
    index: 1,
  },
  {
    icon: FiBarChart2,
    page: "Task",
    path: "/dashboard/task",
    index: 1,
  },
  {
    icon: FaFileContract,
    page: "Stories",
    path: "/dashboard/stories",
    index: 1,
  },
];

export default SideBar;
