import React from "react";
import { TrackDiv } from "./styles/track.style";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyState from "../../common/emptyState";
import Skeleton from "react-loading-skeleton";

const RecentTask = () => {
  const { pendingAssessment, issearchingLoading } = useSelector(
    (state) => state.assessment
  );

  const navigate = useNavigate();

  return (
    <TrackDiv>
      <div className="track">
        <div className="track_header">
          <h3>Recent Task</h3>
        </div>

        <div className="track_table">
          <div className="track_table_headers">
            <div className="track_table_headers_State SN">
              <p>Course</p>
            </div>

            <div className="track_table_headers_State Name">
              <p>Task</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>Status</p>
            </div>

            <div className="track_table_headers_State Description">
              <p>Score</p>
            </div>

            <div className="track_table_headers_State Level">
              <p>Action</p>
            </div>
          </div>

          {!issearchingLoading ? (
            <>
              {pendingAssessment?.length > 0 ? (
                pendingAssessment?.map((item, key) => (
                  <div className="track_table_body" key={key}>
                    <div className="track_table_body_State S_SN">
                      <p>{item?.course}</p>
                    </div>

                    <div className="track_table_body_State S_Name">
                      <p>{item?.title}</p>
                    </div>

                    <div className="track_table_body_State S_Description">
                      <p
                        style={{
                          color: `${
                            item?.status === "pending"
                              ? "##f4be37"
                              : item?.status === "submitted"
                              ? "#12b76a"
                              : "#f14437"
                          }`,
                        }}
                      >
                        {item?.status}
                      </p>
                    </div>

                    <div className="track_table_body_State S_Description">
                      <p>{item?.score}</p>
                    </div>

                    <div className="track_table_body_State S_Level">
                      <button
                        onClick={() => navigate(`/dashboard/task/${item?._id}`)}
                      >
                        View
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyState />
              )}
            </>
          ) : (
            <Skeleton height={80} width={"100%"} count={5} />
          )}
        </div>
      </div>
    </TrackDiv>
  );
};

export default RecentTask;
