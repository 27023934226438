import React from "react";
import SectionModalCentered from "../modal";
import Success from "../../../Assets/images/success-oyola.gif";
import { SuccessModalDiv } from "./style/successModal.style";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset } from "../../../features/auth/authSlice";

const SuccessModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <SectionModalCentered open={true}>
      <SuccessModalDiv className="comfirmationWrapper">
        <div className="comfirmation__firstLayout">
          <div className="comfirmation__firstLayoutImageBody">
            <img
              src={Success}
              alt={"success"}
              className="comfirmation__firstLayoutImage"
            />
          </div>
        </div>
        <div className="comfirmation__secondLayout">
          <h3>Registered Successfully</h3>
          <p>
            Hey there! We've just sent you an email. Please check your inbox,
            and if you can't find it, don't hesitate to reach out to our
            friendly customer support team. We've also included a WhatsApp
            community link in the email. Feel free to join our community for
            more update! Thanks!
          </p>
        </div>
        <div className="comfirmation__thirdLayout">
          <button
            onClick={() => {
              dispatch(reset());

              navigate("/");
            }}
            className="comfirmation__thirdLayoutHistory"
          >
            Welcome aboard!
          </button>
        </div>
      </SuccessModalDiv>
    </SectionModalCentered>
  );
};

export default SuccessModal;
